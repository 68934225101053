import React from 'react';
import {Line} from 'react-chartjs-2';
import {Chart as ChartJS, Legend, LinearScale, LineElement, TimeScale, TimeSeriesScale, Title, Tooltip} from 'chart.js';
import 'chartjs-adapter-date-fns'; // For date handling in Chart.js
import {addHours, format} from 'date-fns';
import zoomPlugin from 'chartjs-plugin-zoom';

// Register Chart.js components
ChartJS.register(TimeSeriesScale, LineElement, TimeScale, LinearScale, Title, Tooltip, Legend, zoomPlugin);


const TimeSeriesChart = ({timeSeriesData, title}) => {
    // Convert Map to arrays
    //console.log(timeSeriesData);

    if (timeSeriesData.length === 0) {
        const fixedLabels = ['', '03 AM', '06 AM', '09 AM', '12 PM', '03 PM', '06 PM', '09 PM', ''];
        const chartData = {
            fixedLabels,
            datasets: [
                {
                    label: title,
                    data: [],
                    borderColor: 'rgba(75, 192, 192, 1)',
                    backgroundColor: 'rgba(75, 192, 192, 0.2)',
                    fill: true,
                },
            ],
        };
        return (<div>
            <h2>{title}</h2>
            <Line data={chartData}/>
        </div>);
    }
    const labels = timeSeriesData.map(dateStr => new Date(dateStr[0]));//convertToLocalTime(dateStr[0]));
    const data = timeSeriesData.map(dateStr => dateStr[1]);
    //console.log(labels);

    const begin = labels[0];
    const startDate = new Date(begin.getFullYear(), begin.getMonth(), begin.getDate(), 0, 0, 0);

    //console.log('start chart: ', startDate);
    const end = labels[labels.length - 1];
    const endDate = new Date(end.getFullYear(), end.getMonth(), end.getDate(), 23, 59, 59);
    //console.log('end chart: ', endDate);

    const fixedLabels = [];
    for (let dt = startDate; dt <= endDate; dt = addHours(dt, 3)) {
        fixedLabels.push(dt);
    }
    //console.log(fixedLabels);

    const chartData = {
        fixedLabels,
        datasets: [
            {
                label: title,
                data: data.map((value, index) => ({
                    x: labels[index],
                    y: value,
                })),
                borderColor: 'rgba(75, 192, 192, 1)',
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
            },
        ],
    };


    const options = {
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'minute', // or 'hour', 'day', etc.
                    stepSize: 1,
                },
                title: {
                    display: true,
                    text: 'Time',
                },
                ticks: {
                    source: 'auto',
                    // Display start and end labels formatted as "MMM d"

                },
            },
            y: {
                title: {
                    display: true,
                    text: title,
                },
            },
        },
        plugins: {
            tooltip: {
                callbacks: {
                    title: (tooltipItems) => {
                        const isoString = tooltipItems[0].raw.x;
                        return format(isoString, 'hh:mm:ss'); // Format date for tooltip
                    },
                },
            },
        },
    };

    return (
        <div>
            <h2>{title}</h2>
            <Line data={chartData} options={options}/>
        </div>
    );
};

export default TimeSeriesChart;