import React, {useEffect, useState} from 'react';
import {Badge, CloseButton} from 'react-bootstrap';
import {useSearchParams} from 'react-router-dom';

const AppliedFilters = ({validFilterNames}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [filters, setFilters] = useState([]);

    useEffect(() => {
        const params = {};
        Object.keys(validFilterNames).forEach((name) => {
            if (searchParams.get(name)) {
                params[name] = searchParams.get(name);
            }
        })
        setFilters(Object.entries(params));
    }, [searchParams]);

    const handleRemoveFilter = (key) => {
        searchParams.delete(key);
        setSearchParams(searchParams);
    };

    return (
        <div className="d-flex flex-wrap gap-2 m-2">
            {filters.map(([key, value]) => (
                <Badge key={key} bg="primary" pill className="d-flex align-items-center btn-outline-primary">
                    {`${validFilterNames[key]}: ${value}`}
                    <CloseButton
                        variant="white"
                        className="ms-2"
                        onClick={() => handleRemoveFilter(key)}
                    />
                </Badge>
            ))}
        </div>
    );
};

export default AppliedFilters;
