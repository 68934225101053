import React from 'react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {addSeconds, isBefore} from 'date-fns';

const DeviceStatusBadge = ({lastSeen, conf, classname = ''}) => {
    const offlineThreshold = addSeconds(new Date(lastSeen), 24 * 60 * 60);

    const isOffline = !lastSeen || isBefore(offlineThreshold, new Date());

    return (
        <OverlayTrigger
            placement="bottom"
            overlay={
                isOffline ?
                    <Tooltip id="button-tooltip-2" className="bg-white">
                        Offline means last activity was observed more than 24 hours ago.
                    </Tooltip> :
                    <div/>}>
            {({ref, ...triggerHandler}) => (
                <span ref={ref} {...triggerHandler}
                      className={isOffline ? 'badge border border-danger text-danger ' + classname : 'badge border border-success text-success ' + classname}>
            {isOffline ? 'Offline'.toUpperCase() : 'Online'.toUpperCase()}
        </span>
            )}
        </OverlayTrigger>
    );
};

export default DeviceStatusBadge;
