import React, {useEffect, useState} from 'react'
import classNames from "classnames"
import {useAuth} from "../../guards/authProvider";
import {useProgress} from "../../progress/ProgressProvider";
import {useCompanyProvider} from "../../guards/companyProvider";
import AppToast from "../../components/toast/AppToast";
import apiCall from "../../guards/apiCall";

const AppWrap = ({className, ...props}) => {
    const [fetching, setFetching] = useState(true);

    const {user, userDetails, getUser} = useAuth();
    const {company, getCompany} = useCompanyProvider();
    const {startLoading, stopLoading} = useProgress();

    useEffect(() => {
        apiCall([user, company], [() => getUser(), () => getCompany(userDetails.companyId)], () => {
            setFetching(true);
            startLoading();
        }, () => {
            stopLoading();
            setFetching(false);
        }, (error) => {
            AppToast.error(error.message);
        }).catch(error => {
            AppToast.error(error.message);
        });

    }, [userDetails]);

    if (fetching) {
        return (<div>Loading...</div>);
    }
    const compClass = classNames({
        "nk-wrap": true,
        [`${className}`]: className,
    });
    return (
        <div className={compClass}>
            {props.children}
        </div>
    )
}

export default AppWrap