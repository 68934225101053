import React, {useEffect, useRef, useState} from 'react';
import {Alert, Button, Card, Col, Form, Row} from 'react-bootstrap';
import {Link, useNavigate} from "react-router-dom";

import Layout from '../../layout/fullpage';
import AppToast from "../../components/toast/AppToast";

function AuthResetPage() {
    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState({});
    const [validated, setValidated] = useState(false);
    const navigate = useNavigate();
    const firstInputRef = useRef(null);

    useEffect(() => {
        // Focus the first input when the component mounts
        if (firstInputRef.current) {
            firstInputRef.current.focus();
        }
    }, []);
    const handleChange = (e) => {
        setEmail(e.target.value);
    };

    const validateForm = () => {
        const newErrors = {};
        if (!email) {
            newErrors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            newErrors.email = 'Email address is invalid';
        }
        return newErrors;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const formErrors = validateForm();
        if (Object.keys(formErrors).length === 0) {
            setValidated(true);
            // Simulate sending password reset email
            fetch(`${process.env.REACT_APP_API_BASE_URL}/forgot-password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({email}),
            })
                .then(response => response.json())
                .then(data => {
                    if (data.success) {
                        AppToast.success('Password reset email sent!');
                        navigate('/home');
                    } else {
                        AppToast.error('Failed to send password reset email.');
                    }
                })
                .catch(error => {
                    //console.log(error);
                    AppToast.error('An error occurred. Please try again later.');
                });
        } else {
            setValidated(false);
            setErrors(formErrors);
        }
    };

    return (
        <Layout title="Forgot Password" centered>
            <div className="container p-2 p-sm-4">
                <Card className="overflow-hidden card-gutter-lg rounded-4 card-auth card-auth-mh">
                    <Row className="justify-content-around">
                        <Col lg="5">
                            <Card.Body className="h-100 d-flex flex-column justify-content-center">
                                <div className="nk-block-head text-center">
                                    <div className="nk-block-head-content">
                                        <h3 className="nk-block-title mb-2">Reset password</h3>
                                        <p className="small col-md-10 mx-auto">If you forgot your password, don't worry!
                                            we’ll email you instructions to reset your password.</p>
                                    </div>
                                </div>
                                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                    <Row className="gy-3">
                                        <Col className="col-12">
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="email">Email</Form.Label>
                                                <div className="form-control-wrap">
                                                    <Form.Control
                                                        type="email"
                                                        name="email"
                                                        placeholder="Enter your email"
                                                        value={email}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.email}
                                                        ref={firstInputRef}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.email}
                                                    </Form.Control.Feedback>
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <Col className="col-12">
                                            <div className="d-grid">
                                                <Button type="submit">Send Reset Link</Button>
                                            </div>
                                        </Col>
                                        <Col className="col-12">
                                            {!validated && Object.keys(errors).length > 0 && (
                                                <Alert variant="danger" className="mt-3">
                                                    Please correct the errors and try again.
                                                </Alert>
                                            )}
                                        </Col>
                                    </Row>
                                </Form>
                                <div className="text-center mt-4">
                                    <p className="small"><Link to="/login">Back to Login</Link></p>
                                </div>
                            </Card.Body>
                        </Col>
                    </Row>
                </Card>
            </div>
        </Layout>
    )
}

export default AuthResetPage;
