import React from 'react'

function Footer() {
    return (
        <div className="nk-footer">
            <div className="container-fluid">
                <div className="nk-footer-wrap justify-content-around">
                    <div className="nk-footer-copyright">
                        &copy; 2024. Developed by Alpha Omega IoT Cloud
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer