import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from "react-router-dom";
import App from './App';

import 'react-datepicker/dist/react-datepicker.css';
import "./assets/scss/style.scss";
import 'react-toastify/dist/ReactToastify.css';
import 'leaflet/dist/leaflet.css';

import reportWebVitals from './reportWebVitals';
import {ToastContainer} from "react-toastify";
import {AuthProvider} from "./guards/authProvider";
import {CompanyProvider} from "./guards/companyProvider";
import {ProgressProvider} from "./progress/ProgressProvider";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <div>
            <ProgressProvider>
                <ToastContainer/>
                <BrowserRouter>
                    <AuthProvider>
                        <CompanyProvider>
                            <App/>
                        </CompanyProvider>
                    </AuthProvider>
                </BrowserRouter>
            </ProgressProvider>
        </div>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
