import {useDashboardProvider} from "./dashboardProvider";
import Layout from "../../layout/default";
import {Card, Col, ListGroup, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import {ChartLegend, Icon, Timeline} from "../../components";
import {ChartBar, ChartDoughnut} from "../../components/Chart/Charts";
import {Colors} from "../../utilities";
import {useEffect} from "react";
import {useAuth} from "../../guards/authProvider";
import AppToast from "../../components/toast/AppToast";
import {format, startOfToday, subDays} from 'date-fns';

const DashboardPage = () => {

    const {userDetails} = useAuth();
    const {dashboardDto, getDashboard} = useDashboardProvider();

    const fetchDashboard = async () => {
        await getDashboard().catch((error) => {
            AppToast.error(error.message);
        });
    }
    useEffect(() => {
        fetchDashboard();
    }, [userDetails]);


    let devicesByStatus = {
        labels: ["Online", "Offline"],
        datasets: [
            {
                backgroundColor: [Colors.info, Colors.yellow, Colors.green, Colors.purple],
                data: dashboardDto ? [1, dashboardDto.devicesCount - 1] : [5, 1],
                hoverOffset: 4
            }
        ]
    };

    let sessionsDevice = {
        labels: ["Online", "Offline"],
        datasets: [
            {
                backgroundColor: [Colors.info, Colors.yellow, Colors.green, Colors.purple],
                data: dashboardDto ? [1, dashboardDto.gatewaysCount - 1] : [5, 1],
                hoverOffset: 4
            }
        ]
    };
    const getLast30DaysLabels = () => {
        const today = startOfToday();

        // Generate an array of dates for the last 30 days
        const labels = Array.from({length: 30}, (_, i) => {
            // Calculate the date for each day
            const date = subDays(today, i);

            // Format the date to 'dd MMM'
            return format(date, 'dd MMM');
        }).reverse(); // Reverse to have the most recent date first

        return labels;
    };

    let sensorDataDailyCount = {
        labels: dashboardDto ? dashboardDto.sensorDataDailyCount.map(pair => format(new Date(Object.keys(pair)[0]), 'dd MMM')) : getLast30DaysLabels(),
        xAxis: true,
        borderDash: [8, 4],
        barThickness: 6,
        datasets: [
            {
                borderRadius: 2,
                borderColor: Colors.primary,
                backgroundColor: Colors.primary,
                label: "Device data received daily",
                data: dashboardDto ? dashboardDto.sensorDataDailyCount.map(pair => Object.values(pair)[0]) : []
            },
        ]
    };

    let faultyDataDailyCount = {
        labels: dashboardDto ? dashboardDto.faultyDataDailyCount.map(pair => format(new Date(Object.keys(pair)[0]), 'dd MMM')) : getLast30DaysLabels(),
        xAxis: true,
        borderDash: [8, 4],
        barThickness: 6,
        datasets: [
            {
                borderRadius: 2,
                borderColor: Colors.danger,
                backgroundColor: Colors.danger,
                label: "Faulty data received daily",
                data: dashboardDto ? dashboardDto.faultyDataDailyCount.map(pair => Object.values(pair)[0]) : []
            },
        ]
    };
    const currentMonth = format(new Date(), 'MMM');

    return (
        <Layout title="Dashboard">
            <Row className="g-gs">
                <Col sm="6" xxl="4">
                    <Card className="h-100">
                        <Card.Body>
                            <div className="card-title-group align-items-start">
                                <div className="card-title">
                                    <h4 className="title">Total Data Received Daily</h4>
                                </div>
                            </div>
                            <div className="mt-2 mb-4">
                                <div className="amount h1">2,765</div>
                                <div className="d-flex align-items-center smaller">
                                    <div className="change up">
                                        <Icon name="upword-alt-fill"></Icon>
                                        <span className="ms-1">10.5%</span>
                                    </div>
                                    <span className="text-light">From last month</span>
                                </div>
                            </div>
                            <div className="nk-chart-analytics-session">
                                <ChartBar data={sensorDataDailyCount}/>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm="6" xxl="4">
                    <Card className="h-100">
                        <Card.Body>
                            <div className="card-title-group align-items-start">
                                <div className="card-title">
                                    <h4 className="title">Faulty Data Received Daily</h4>
                                </div>
                            </div>
                            <div className="mt-2 mb-4">
                                <div className="amount h1">0</div>
                                <div className="d-flex align-items-center smaller">
                                    <div className="change up">
                                        <Icon name="upword-alt-fill"></Icon>
                                        <span className="ms-1">0.0%</span>
                                    </div>
                                    <span className="text-light">From last month</span>
                                </div>
                            </div>
                            <div className="nk-chart-analytics-session">
                                <ChartBar data={faultyDataDailyCount}/>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>

                <Col sm="6" xxl="2">
                    <Card className="h-100">
                        <Card.Body>
                            <div className="card-title-group align-items-start">
                                <div className="card-title">
                                    <h4 className="title">Users</h4>
                                </div>
                            </div>
                            <div className="mt-2 mb-4">
                                <div className="amount h1">{dashboardDto?.usersCount}</div>
                                <div className="d-flex align-items-center smaller">
                                    <div className="change up">
                                        <Icon name="upword-alt-fill"></Icon>
                                        <span className="ms-1">1.5%</span>
                                    </div>
                                    <span className="text-light">From last month</span>
                                </div>
                            </div>

                            <div className="card-title-group align-items-start">
                                <div className="card-title">
                                    <h4 className="title">Devices</h4>
                                </div>
                            </div>
                            <div className="mt-2 mb-4">
                                <div className="amount h1">{dashboardDto?.devicesCount}</div>
                                <div className="d-flex align-items-center smaller">
                                    <div className="change up">
                                        <Icon name="upword-alt-fill"></Icon>
                                        <span className="ms-1">1.5%</span>
                                    </div>
                                    <span className="text-light">From last month</span>
                                </div>
                            </div>


                        </Card.Body>
                    </Card>
                </Col>
                <Col sm="6" xxl="2">
                    <Card className="h-100">
                        <Card.Body>
                            <div className="card-title-group align-items-start">
                                <div className="card-title">
                                    <h4 className="title">Gateways</h4>
                                </div>
                            </div>
                            <div className="mt-2 mb-4">
                                <div className="amount h1">{dashboardDto?.gatewaysCount}</div>
                                <div className="d-flex align-items-center smaller">
                                    <div className="change up">
                                        <Icon name="upword-alt-fill"></Icon>
                                        <span className="ms-1">1.5%</span>
                                    </div>
                                    <span className="text-light">From last month</span>
                                </div>
                            </div>
                            {dashboardDto?.companyCount && <div className="card-title-group align-items-start">
                                <div className="card-title">
                                    <h4 className="title">Companies</h4>
                                </div>
                            </div>}
                            {dashboardDto?.companyCount &&
                                (<div className="mt-2">
                                    <div className="amount h1">{dashboardDto?.companyCount}</div>
                                    <div className="d-flex align-items-center smaller">
                                        <div className="change up">
                                            <Icon name="upword-alt-fill"></Icon>
                                            <span className="ms-1">1.5%</span>
                                        </div>
                                        <span className="text-light">From last month</span>
                                    </div>
                                </div>)
                            }
                        </Card.Body>
                    </Card>
                </Col>
                <Col md="6" xxl="4">
                    <Card className="h-100">
                        <Card.Body>
                            <div className="card-title-group">
                                <div className="card-title">
                                    <h5 className="title">Recent Activity</h5>
                                </div>
                            </div>
                            <Timeline className="nk-timeline-center mt-4">
                                <Timeline.Group title="today">
                                    <Timeline.List>
                                        <Timeline.Item symbol="alert" variant="warning">
                                            <p className="small"><strong>One Channel Vibration Plus</strong> was
                                                in <strong>Rough condition</strong> in a new post</p>
                                        </Timeline.Item>
                                        <Timeline.Item symbol="check" variant="success">
                                            <p className="small"><strong>One Channel Vibration Plus</strong> is back
                                                to
                                                <strong>Normal</strong></p>
                                        </Timeline.Item>
                                    </Timeline.List>
                                </Timeline.Group>
                            </Timeline>
                        </Card.Body>
                    </Card>
                </Col>


                <Col md="6" xxl="4">
                    <Card className="h-100">
                        <Card.Body>
                            <div className="card-title-group">
                                <div className="card-title">
                                    <h5 className="title">Devices by status</h5>
                                </div>
                                <div className="card-tools">
                                    <OverlayTrigger
                                        placement="left"
                                        overlay={
                                            <Tooltip id="tooltip-another">Devices by status</Tooltip>
                                        }>
                                        <em className="icon-hint icon ni ni-help-fill"></em>
                                    </OverlayTrigger>
                                </div>
                            </div>
                            <div className="nk-chart-analytics-session-device mt-4">
                                <ChartDoughnut data={devicesByStatus}/>
                            </div>
                            <ChartLegend.Group className="justify-content-around pt-4 flex-wrap gap g-2">
                                <ChartLegend symbol="info">
                                    Online
                                </ChartLegend>
                                <ChartLegend symbol="warning">
                                    Offline
                                </ChartLegend>
                            </ChartLegend.Group>
                        </Card.Body>
                    </Card>
                </Col>

                <Col md="6" xxl="4">
                    <Card className="h-100">
                        <Card.Body>
                            <div className="card-title-group">
                                <div className="card-title">
                                    <h5 className="title">Gateways by status</h5>
                                </div>
                                <div className="card-tools">
                                    <OverlayTrigger
                                        placement="left"
                                        overlay={
                                            <Tooltip id="tooltip-another">Gateways by status</Tooltip>
                                        }>
                                        <em className="icon-hint icon ni ni-help-fill"></em>
                                    </OverlayTrigger>
                                </div>
                            </div>
                            <div className="nk-chart-analytics-session-device mt-4">
                                <ChartDoughnut data={sessionsDevice}/>
                            </div>
                            <ChartLegend.Group className="justify-content-around pt-4 flex-wrap gap g-2">
                                <ChartLegend symbol="info">
                                    Online
                                </ChartLegend>
                                <ChartLegend symbol="warning">
                                    Offline
                                </ChartLegend>
                            </ChartLegend.Group>
                        </Card.Body>
                    </Card>
                </Col>


                <Col xxl="4">
                    <Card className="h-100">
                        <div className="card-body">
                            <div className="card-title-group">
                                <div className="card-title">
                                    <h5 className="title">Devices by Region</h5>
                                </div>
                                <div className="card-tools">
                                    <OverlayTrigger
                                        placement="left"
                                        overlay={
                                            <Tooltip id="tooltip-another">Devices from region</Tooltip>
                                        }>
                                        <em className="icon-hint icon ni ni-help-fill"></em>
                                    </OverlayTrigger>
                                </div>
                            </div>
                            <div className="list-group-heading d-flex align-items-center justify-content-between">
                                <h6 className="title">Top Region</h6>
                                <h6 className="title">Devices</h6>
                            </div>
                            <ListGroup className="list-group-borderless list-group-sm">
                                <ListGroup.Item
                                    className="d-flex justify-content-between align-items-center smaller">
                                    <span className="title">Canada</span>
                                    <span className="text">1</span>
                                </ListGroup.Item>
                                <ListGroup.Item
                                    className="d-flex justify-content-between align-items-center smaller">
                                    <span className="title">United States</span>
                                    <span className="text">0</span>
                                </ListGroup.Item>
                                <ListGroup.Item
                                    className="d-flex justify-content-between align-items-center smaller">
                                    <span className="title">United Kingdom</span>
                                    <span className="text">0</span>
                                </ListGroup.Item>
                                <ListGroup.Item
                                    className="d-flex justify-content-between align-items-center smaller">
                                    <span className="title">Germany</span>
                                    <span className="text">0</span>
                                </ListGroup.Item>
                            </ListGroup>
                        </div>
                    </Card>
                </Col>
            </Row>
        </Layout>
    )
}

export default DashboardPage;