import axiosInstance from "../../interceptor/axiosInterceptor";
import {createContext, useContext, useState} from "react";

const GatewaysContext = createContext(null);

export const GatewaysProvider = ({children}) => {
    //console.log('DevicesProvider');
    const [devices, setDevices] = useState(null);
    const getGatewaysForBranch = async (companyId, branchId) => {
        //console.log("getDevicesForBranch", companyId, branchId);
        await axiosInstance.get(`companies/${companyId}/branches/${branchId}/gateways`).then((response) => {
            if (response.status === 200) {
                //console.log(response.data);
                setDevices({...devices, [branchId]: response.data});
                return response.data;
            }
            return Promise.reject('Error while getting devices');
        }).catch((error) => {
            //console.log(error);
            return Promise.reject(error);
        });
    }
    return <GatewaysContext.Provider value={{devices, getGatewaysForBranch}}>{children}</GatewaysContext.Provider>;
}

export const useGatewaysProvider = () => useContext(GatewaysContext);
