import EditDeviceModal from "./EditDeviceModal";
import React, {useEffect, useState} from "react";
import AppDataTable from "../components/AppDataTable";
import FormFilter from "../../components/Filter/FormFilter";
import DeviceStatusBadge from "./DeviceStatusBadge";
import {Button} from "react-bootstrap";
import {Icon} from "../../components";
import {formatDateToLastSeen, formatInterval, objectToQueryParamString} from "./DeviceList";
import {useAuth} from "../../guards/authProvider";
import {useNavigate} from "react-router-dom";
import Swal from "sweetalert2";
import axiosInstance from "../../interceptor/axiosInterceptor";
import AppliedFilters from "./AppliedFilters";

const DeviceDataTable = ({queryParams, selectedBranchId, deviceSearchQueries}) => {
    const {userDetails} = useAuth();
    const navigate = useNavigate();

    const uriSafeQueryParams = decodeURIComponent(objectToQueryParamString(deviceSearchQueries));

    const [editDevice, setEditDevice] = useState(null);
    const [forcedTableRefresh, setForcedTableRefresh] = useState(false);

    useEffect(() => {
            setForcedTableRefresh(!setForcedTableRefresh);
        },
        [userDetails])

    const handleDeviceDetails = (row) => {
        if (row.deviceType === 'VIBRATION_SENSOR') {
            navigate(`/devices/${row.id}/vibration-device-details?${queryParams}`, {state: {device: row}});
        } else {
            navigate(`/devices/${row.id}/device-details?${queryParams}`, {state: {device: row}});
        }
    };

    const openEditDeviceModal = (row) => {
        setEditDevice(row);
    }

    const onEditModalHide = (updatedDevice) => {
        if (updatedDevice) {
            setEditDevice(null);
            setForcedTableRefresh(!forcedTableRefresh);
        } else {
            setEditDevice(undefined);
        }
    }

    const handleDeviceEuiSearch = (text) => {
        queryParams.delete('deviceMacAddress');
        if (text) {
            navigate(`/devices?${queryParams}&deviceMacAddress=${text}`);
        } else {
            navigate(`/devices?${queryParams}`);
        }
    }

    const handleDeviceStatusSearch = (status) => {
        queryParams.delete('status');
        if (status) {
            navigate(`/devices?${queryParams}&status=${status}`);
        } else {
            navigate(`/devices?${queryParams}`);
        }
    }

    const deleteDevice = (row) => {
        Swal.fire({
            title: `Are you sure, you want to delete device <i>${row.name}</i>?`,
            html: `Deleting device means all associated data related to device will also be deleted. You won't be able to revert this!`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                axiosInstance.delete(`companies/${userDetails.companyId}/branches/${row.branchId}/devices/${row.id}`).then((result) => {
                    Swal.fire('Deleted', 'Device has been deleted.', 'success').then(r => {
                    });
                    setForcedTableRefresh(!forcedTableRefresh);
                }).catch((error) => {
                    const message = error.response?.data?.message || error.message;
                    console.error(message);
                })
            }
        });
    }

    const columns = [
        {
            name: 'Device Name',
            selector: (row) => row.name,
            sortable: false,
            minWidth: '100px'
        },
        {
            selector: (row) => row.deviceEui,
            name: (<FormFilter name="Mac Address" value={deviceSearchQueries.get('deviceMacAddress')}
                               onSearch={handleDeviceEuiSearch}
                               placeholder="Enter mac address" type="text"/>),
            sortable: false,
            minWidth: '100px',
            wrap: false,
        },
        {
            name: 'Read Interval',
            selector: (row) => {
                if (row.readPeriod && row.readPeriod > 0) {
                    return formatInterval(Math.round(row.readPeriod / 60) * 60);
                }
                return '';
            },
            sortable: false,
            wrap: true,
            maxWidth: '120px'
        },
        {
            name: (
                <FormFilter name='Status' value={deviceSearchQueries.get('status')} onSearch={handleDeviceStatusSearch}
                            placeholder="Select status" type="enum"
                            enumItems={['OFFLINE', 'ONLINE']}/>),
            selector: (row) => row,
            cell: row => <DeviceStatusBadge lastSeen={row.timestamp} conf={{readPeriod: row.readPeriod}}/>,
            sortable: false,
            maxWidth: '100px'
        },
        {
            name: 'Last Seen',
            selector: (row) => formatDateToLastSeen(row.timestamp),
            sortable: false,
            minWidth: '100px'
        },
        {
            name: 'Actions',
            cell: (row) => (
                <div>
                    <Button
                        variant="outline-primary"
                        size="sm"
                        onClick={() => handleDeviceDetails(row)}
                        style={{
                            marginRight: '5px', paddingLeft: '0.25rem',
                            paddingRight: '0.25rem',
                            paddingTop: '0.05rem',
                            paddingBottom: '0.05rem'
                        }}
                    >
                        <Icon name={'eye'} size={14}/>
                    </Button>
                    <Button
                        variant="outline-info"
                        size="sm"
                        onClick={() => openEditDeviceModal(row)}
                        style={{
                            paddingLeft: '0.25rem',
                            paddingRight: '0.25rem',
                            paddingTop: '0.05rem',
                            paddingBottom: '0.05rem'
                        }}
                    >
                        <Icon name={'edit'} size={14}/>
                    </Button>
                    <Button
                        variant="outline-danger"
                        size="sm"
                        onClick={() => deleteDevice(row)}
                        style={{
                            marginLeft: '5px',
                            paddingLeft: '0.25rem',
                            paddingRight: '0.25rem',
                            paddingTop: '0.05rem',
                            paddingBottom: '0.05rem'
                        }}
                    >
                        <Icon name={'delete'} size={14}/>
                    </Button>
                </div>
            ),
            ignoreRowClick: true,
            maxWidth: '140px'
        },
    ];

    return (
        <div>
            <div>
                {deviceSearchQueries.size > 0 &&
                    <AppliedFilters validFilterNames={{"deviceMacAddress": "Mac Address", "status": "Status"}}/>}
            </div>
            <AppDataTable title="Devices"
                          url={`companies/${userDetails.companyId}/branches/${selectedBranchId}/devices?${uriSafeQueryParams}`}
                          columns={columns}
                          forceRefresh={forcedTableRefresh}
            />
            <EditDeviceModal show={!!editDevice} device={editDevice} onHide={onEditModalHide}/>
        </div>
    );
}

export default DeviceDataTable;