import React, {createContext, useContext, useState} from "react";
import axiosInstance from "../interceptor/axiosInterceptor";

const CompanyContext = createContext(null);


export const CompanyProvider = ({children}) => {
    const [company, setCompany] = useState(null);
    const [companyList, setCompanyList] = useState(null);

    const getCompany = (id) => {
        if (!id) {
            return Promise.reject('Error company not selected');
        }
        //console.log('getCompany', id);
        return axiosInstance.get(`/companies/${id}`).then((response) => {
            if (response.status === 200) {
                setCompany(response.data);
                //console.log(response.data);
                return response.data;
            }
            return Promise.reject('Error while getting company');
        }).catch(error => {
            //console.log(error);
            return Promise.reject(error);
        });
    }

    const getCompanyList = (id) => {
        return axiosInstance.get(`/companies`).then((response) => {
            if (response.status === 200) {
                setCompanyList(response.data);
                return response.data;
            }
            return Promise.reject('Error while getting company list');
        }).catch(error => {
            return Promise.reject(error);
        })
    }

    return (
        <CompanyContext.Provider value={{company, getCompany, getCompanyList, companyList}}>
            {children}
        </CompanyContext.Provider>
    );
}

export const useCompanyProvider = () => useContext(CompanyContext);
