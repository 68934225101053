import React, {useEffect, useRef, useState} from 'react';
import {Alert, Button, Card, Col, Form, Row} from 'react-bootstrap';
import {Link, useNavigate} from "react-router-dom";

import Layout from '../../layout/fullpage';
import {API_BASE_URL} from "../../config/config";
import axiosInstance from "../../interceptor/axiosInterceptor";
import AppToast from "../../components/toast/AppToast";
import {useAuth} from "../../guards/authProvider";

const AuthLoginPage = () => {
    const [formData, setFormData] = useState({
        username: '',
        password: ''
    });
    const [errors, setErrors] = useState({});
    const [validated, setValidated] = useState(false);
    const navigate = useNavigate();
    const {login} = useAuth();
    const firstInputRef = useRef(null);

    useEffect(() => {
        if (firstInputRef.current) {
            firstInputRef.current.focus();
        }

    }, []);
    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.username) newErrors.username = 'Username is required';
        if (!formData.password) newErrors.password = 'Password is required';
        return newErrors;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const formErrors = validateForm();
        if (Object.keys(formErrors).length === 0) {
            setValidated(true);
            handleLogin(formData)
        } else {
            setValidated(false);
            setErrors(formErrors);
        }
    };

    const handleLogin = () => {
        axiosInstance.post(`${API_BASE_URL}/login`, formData)
            .then(response => {
                if (response.status === 200 && response.headers['authorization']) {
                    //console.log('login successful');
                    //console.log(response);
                    //console.log(response.headers['authorization']);
                    login(response.headers['authorization'].split(' ')[1]);

                    navigate('/home');
                } else {
                    AppToast.error('Login failed. Please check your credentials.');
                }
            })
            .catch(error => {
                if (error.code === 401) {
                    navigate('/login');
                }
                AppToast.error('Login failed. Please try again later.');
                console.error('Error app:', error);
            });
    }
    return (
        <>
            <Layout title="Login" centered>
                <div className="container p-2 p-sm-4">
                    <Card className="overflow-hidden card-gutter-lg rounded-4 card-auth card-auth-mh">
                        <Row className="justify-content-center">
                            <Col lg="5">
                                <Card.Body className="h-100 d-flex flex-column justify-content-center">
                                    <div className="nk-block-head text-center">
                                        <div className="nk-block-head-content">
                                            <h3 className="nk-block-title mb-1">Login to Account</h3>
                                        </div>
                                    </div>
                                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                        <Row className="gy-3">
                                            <Col className="col-12">
                                                <Form.Group className="form-group">
                                                    <Form.Label htmlFor="email">Email</Form.Label>
                                                    <div className="form-control-wrap">
                                                        <Form.Control
                                                            type="text"
                                                            name="username"
                                                            placeholder="Enter username"
                                                            value={formData.username}
                                                            onChange={handleChange}
                                                            isInvalid={!!errors.username}
                                                            ref={firstInputRef}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.username}
                                                        </Form.Control.Feedback>
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                            <Col className="col-12">
                                                <Form.Group className="form-group">
                                                    <Form.Label htmlFor="password">Password</Form.Label>
                                                    <div className="form-control-wrap">
                                                        <Form.Control
                                                            type="password"
                                                            name="password"
                                                            placeholder="Enter password"
                                                            value={formData.password}
                                                            onChange={handleChange}
                                                            isInvalid={!!errors.password}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.password}
                                                        </Form.Control.Feedback>
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                            <Col className="col-12">
                                                <div className="d-flex flex-wrap justify-content-between">
                                                    <Form.Check
                                                        className="form-check-sm"
                                                        type="checkbox"
                                                        id="rememberMe"
                                                        label="Remember Me"
                                                    />
                                                    <Link to="/forgotPassword" className="small">Forgot
                                                        Password?</Link>
                                                </div>
                                            </Col>
                                            <Col className="col-12">
                                                <div className="d-grid">
                                                    <Button type="submit">Login to account</Button>
                                                </div>
                                            </Col>

                                            {!validated && Object.keys(errors).length > 0 && (
                                                <Col>
                                                    <Alert variant="danger" className="mt-3">
                                                        Please correct the errors and try again.
                                                    </Alert>
                                                </Col>
                                            )}
                                        </Row>
                                    </Form>
                                    <div className="text-center mt-4">
                                        <p className="small">Don't have an account? <Link
                                            to="/register">Register</Link></p>
                                    </div>
                                </Card.Body>
                            </Col>
                        </Row>
                    </Card>
                </div>
            </Layout>
        </>
    )
}

export default AuthLoginPage;
