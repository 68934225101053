import {Button, Card} from 'react-bootstrap';
import {useLocation, useNavigate} from 'react-router-dom';

import Layout from '../../layout/default';
import Block from '../../components/Block/Block';
import {Icon} from '../../components';
import {useAuth} from "../../guards/authProvider";
import AppDataTable from "../components/AppDataTable";
import {formatDateToLastSeen, objectToQueryParamString} from "../devices/DeviceList";
import {GatewaysProvider} from "./GatewayProvider";
import DeviceStatusBadge from "../devices/DeviceStatusBadge";
import BranchSelector from "../branch/BranchSelector";
import {useCompanyProvider} from "../../guards/companyProvider";
import MapWithLocations from "../../components/map/MapWithLocations";
import React, {useState} from "react";
import FormFilter from "../../components/Filter/FormFilter";
import EditGatewayModal from "./EditGateway";
import AddGatewayModal from "./AddGateway";
import Swal from "sweetalert2";
import axiosInstance from "../../interceptor/axiosInterceptor";
import AppliedFilters from "../devices/AppliedFilters";

const GatewayList = () => {
    const {userDetails} = useAuth();
    const {company} = useCompanyProvider();
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const selectedBranchId = decodeURIComponent(queryParams.get('branchId') || 'all');
    const [editGateway, setEditGateway] = useState(null);
    const [addGateway, setAddGateway] = useState(false);
    const [locations, setLocations] = useState([]);
    const [forcedTableRefresh, setForcedTableRefresh] = useState(false);

    const deviceSearchQueries = new Map();
    if (queryParams.get('gatewayMacAddress')) {
        deviceSearchQueries.set('gatewayMacAddress', queryParams.get('gatewayMacAddress'));
    }
    const uriSafeQueryParams = decodeURIComponent(objectToQueryParamString(deviceSearchQueries));

    const handleDeviceEuiSearch = (text) => {
        queryParams.delete('gatewayMacAddress');
        if (text) {
            navigate(`/gateways?${queryParams}&gatewayMacAddress=${text}`);
        } else {
            navigate(`/gateways?${queryParams}`);
        }
    }

    const openEditGatewayModal = (row) => {
        setEditGateway(row);
    }

    const onEditModalHide = (updatedGateway) => {
        if (updatedGateway) {
            setEditGateway(null);
            setForcedTableRefresh(!forcedTableRefresh);
        } else {
            setEditGateway(undefined);
        }
    }

    const onGatewayAdd = (newGateway) => {
        if (newGateway) {
            setAddGateway(false);
            setForcedTableRefresh(!forcedTableRefresh);
        } else {
            setAddGateway(false);
        }
    }

    const deleteGateway = (row) => {
        Swal.fire({
            title: `Are you sure, you want to delete gateway <i>${row.name}</i>?`,
            html: `Deleting device means all associated data related to device will also be deleted. You won't be able to revert this!`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                axiosInstance.delete(`companies/${userDetails.companyId}/branches/${selectedBranchId}/gateways/${row.id}`).then((result) => {
                    Swal.fire('Deleted', 'Gateway has been deleted.', 'success').then(r => {
                    });
                    setForcedTableRefresh(!forcedTableRefresh);
                }).catch((error) => {
                    const message = error.response?.data?.message || error.message;
                    console.error(message);
                })
            }
        });
    }

    const columns = [
        {
            name: 'Name',
            selector: (row) => row.name,
            sortable: false,
            minWidth: '100px',
        },
        {
            name: (<FormFilter name="Mac Address" value={deviceSearchQueries.get('gatewayMacAddress')}
                               onSearch={handleDeviceEuiSearch}
                               placeholder="Enter mac address" type="text"/>),
            selector: (row) => row.gatewayEui,
            sortable: false,
            minWidth: '100px'
        },
        {
            name: 'Status',
            selector: (row) => row,
            cell: row => <DeviceStatusBadge lastSeen={row.lastSeenTimestamp} conf={{readPeriod: 600}}/>,
            sortable: false,
            maxWidth: '100px',
        },
        {
            name: 'Last Seen',
            selector: (row) => formatDateToLastSeen(row.lastSeenTimestamp),
            sortable: false,
            minWidth: '100px',
        },
        {
            name: 'Actions',
            cell: (row) => (
                <div>
                    <Button
                        variant="outline-info"
                        size="sm"
                        onClick={() => openEditGatewayModal(row)}
                        style={{
                            paddingLeft: '0.25rem',
                            paddingRight: '0.25rem',
                            paddingTop: '0.05rem',
                            paddingBottom: '0.05rem'
                        }}
                    >
                        <Icon name={'edit'} size={14}/>
                    </Button>
                    <Button
                        variant="outline-danger"
                        size="sm"
                        onClick={() => deleteGateway(row)}
                        style={{
                            marginLeft: '5px',
                            paddingLeft: '0.25rem',
                            paddingRight: '0.25rem',
                            paddingTop: '0.05rem',
                            paddingBottom: '0.05rem'
                        }}
                    >
                        <Icon name={'delete'} size={14}/>
                    </Button>
                </div>
            ),
            ignoreRowClick: true,
            maxWidth: '100px',
        },
    ];

    const onTableDataLoaded = (data) => {
        const locs = data.reverse().filter(data => data.latitude && data.longitude).map(data => {
            return {id: data.id, latitude: data.latitude, longitude: data.longitude, name: data.name};
        });
        setLocations(locs);
    }

    return (
        <GatewaysProvider>
            <Layout title="Gateways List" content="container">
                <Block.Head>
                    <Block.HeadBetween>
                        <Block.HeadContent>
                            <BranchSelector company={company}/>
                        </Block.HeadContent>
                        <Block.HeadContent>
                            <Block.Title tag="h2">Gateways</Block.Title>
                        </Block.HeadContent>
                        <Block.HeadContent>
                            <ul className="d-flex">
                                <li>
                                    <Button className="d-md-none" size="md" variant="primary"
                                            onClick={() => setAddGateway(true)}>
                                        <Icon name="plus"/>
                                        <span>Add</span>
                                    </Button>
                                </li>
                                <li>
                                    <Button className="d-none d-md-inline-flex" variant="primary"
                                            onClick={() => setAddGateway(true)}>
                                        <Icon name="plus"/>
                                        <span>Add Gateway</span>
                                    </Button>
                                </li>
                            </ul>
                        </Block.HeadContent>
                    </Block.HeadBetween>
                </Block.Head>

                <Card>
                    <Block>
                        <MapWithLocations locations={locations}/>
                    </Block>
                    <Block>
                        {
                            selectedBranchId === 'all' && <Card.Body className="pt-0">
                                <span>Select Branch to show gateway</span>
                            </Card.Body>
                        }

                        {selectedBranchId !== 'all' && (
                            <>
                                <div>
                                    {deviceSearchQueries.size > 0 &&
                                        <AppliedFilters validFilterNames={{"gatewayMacAddress": "Mac Address"}}/>}
                                </div>
                                <AppDataTable title="Gateways"
                                              url={`companies/${userDetails.companyId}/branches/${selectedBranchId}/gateways?${uriSafeQueryParams}`}
                                              columns={columns}
                                              onDataLoaded={onTableDataLoaded}
                                              forceRefresh={forcedTableRefresh}
                                />
                            </>
                        )}
                    </Block>
                </Card>
                <EditGatewayModal show={!!editGateway} gateway={editGateway} onHide={onEditModalHide}/>
                <AddGatewayModal show={addGateway} selectedBranchId={selectedBranchId} onAdd={onGatewayAdd}/>

            </Layout>
        </GatewaysProvider>
    )
}

export default GatewayList;