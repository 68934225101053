import React from 'react';
import {Button} from 'react-bootstrap';
import {addDays, format} from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';
import {FaCalendar} from 'react-icons/fa';
import DatePicker from "react-datepicker";

const CalendarComponent = ({selectedDate, onDateChange, hasTimePicker = false}) => {

    const [show, setShow] = React.useState(false);
    const handlePreviousDay = () => {
        const newDate = addDays(selectedDate, -1);
        if (onDateChange) {
            onDateChange(newDate);
        }
    };

    const handleNextDay = () => {
        const newDate = addDays(selectedDate, 1);
        if (onDateChange) {
            onDateChange(newDate);
        }

    };

    const handleDateChange = (date) => {
        if (onDateChange) {
            onDateChange(date);
        }
        setShow(false);
    };

    const showDatePicker = () => {
        setShow(!show);
    }

    const hideDatePicker = () => {
        if (show) {
            setShow(false);
        }
    }

    return (
        <div>
            <DatePicker
                onClickOutside={() => hideDatePicker()}
                onInputClick={() => showDatePicker()}
                open={show}
                selected={selectedDate}
                onChange={date => handleDateChange(date)}
                customInput={<CustomIconDatePickerInput value={selectedDate} hasTimePicker={hasTimePicker}/>}
            />
        </div>
    );
};

export const CustomIconDatePickerInput = React.forwardRef(({value, hasTimePicker, onClick,}, ref) => (
    <Button size="sm" ref={ref} variant="outline-primary" onClick={onClick} className="d-flex align-items-center"
            style={hasTimePicker ? {
                borderTopRightRadius: '0px',
                borderBottomRightRadius: '0px',
            } : {}}>
        <FaCalendar className="me-1"/>
        {value ? format(value, 'dd MMMM yyyy') : ''}
    </Button>
));

export default CalendarComponent;
