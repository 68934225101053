import React, {useEffect, useState} from 'react';
import {Dropdown, FormControl} from "react-bootstrap";
import axiosInstance from "../../interceptor/axiosInterceptor";
import AppToast from "../../components/toast/AppToast";
import './VibrationTimeSelector.css';
import {format} from 'date-fns';
import {FaClock} from "react-icons/fa";
import Button from "react-bootstrap/Button";

export const getTimeZoneOffset = () => {
    const offset = new Date().getTimezoneOffset();
    return `${offset < 0 ? '+' : '-'}${format(Math.abs(offset), 'HH:mm')}`;
}

const VibrationDeviceTimeSelector = ({selectedFilter, userDetails, branchId, deviceId, from, to, onItemSelected}) => {
    const [deviceDataMetadataDTOs, setDeviceDataMetadataDTOs] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    let metadataMap = {};
    if (deviceDataMetadataDTOs) {
        deviceDataMetadataDTOs.forEach((item) => {
            metadataMap[item.id] = item;
        });
    }

    const fetchDeviceData = async () => {
        await axiosInstance.get(`companies/${userDetails.companyId}/branches/${branchId}/devices/${deviceId}/summary?from=${from}&to=${to}`)
            .then((response) => {
                if (response.status === 200) {
                    setDeviceDataMetadataDTOs(response.data.reverse());
                    setFilterData(response.data);
                    setSelectedItem(null);
                    response.data.forEach((deviceData) => {
                        metadataMap[deviceData.id] = deviceData;
                    });
                    if (response.data.length > 0) {
                        handleFilterChange(response.data[0].id);
                    }
                } else {
                    AppToast.error('Error while fetching device data metadata');
                    setDeviceDataMetadataDTOs([]);
                }
            }).catch((error) => {
                //console.log(error);
                AppToast.error(error.message);
                setDeviceDataMetadataDTOs([]);
            });
    }

    useEffect(() => {
        fetchDeviceData();
    }, [userDetails, from, to]);


    // Handle dropdown selection
    const handleFilterChange = (event) => {
        //console.log(event);
        const filterValue = event;
        // Update the URL with the selected filter
        if (filterValue) {
            setSelectedItem(metadataMap[filterValue]);
            onItemSelected(filterValue);
        } else {
            setSelectedItem(null);
            onItemSelected(undefined);
        }
    };

    const handleInputSearch = (event) => {
        const searchValue = event.target.value;
        setFilterData(deviceDataMetadataDTOs.filter(metadata => formatTimeToHHMM(metadata.timestamp).startsWith(searchValue)));
    }
    const formatTimeToHHMM = (timeString) => {
        return format(new Date(timeString), 'HH:mm');
    }


    const showTimeDropdown = (e) => {
        if (!showDropdown) {
            setShowDropdown(true);
        }
    }

    const hideTimeDropdown = (nextShow) => {
        if (!nextShow) {
            setShowDropdown(false);
        }
    }

    return (
        <Dropdown onSelect={handleFilterChange} show={showDropdown}
                  onToggle={(nextShow, meta) => hideTimeDropdown(nextShow)}>
            <Button size="sm" variant="outline-primary" onClick={showTimeDropdown}
                    className="d-flex align-items-center" style={{
                borderTopLeftRadius: '0px',
                borderLeftWidth: '0px',
                borderStartWidth: '0px',
                borderBottomLeftRadius: '0px',
            }}>
                <FaClock className="me-1"/>
                {selectedItem && selectedItem?.timestamp ? formatTimeToHHMM(selectedItem.timestamp) + ' UTC' + getTimeZoneOffset() : 'Select time'}

            </Button>
            <Dropdown.Menu show={showDropdown} style={{maxHeight: '400px', overflowY: 'auto'}}>
                <Dropdown.Header>
                    <FormControl
                        size="sm"
                        type="text"
                        name="timesearch"
                        placeholder="Enter time to search"
                        onChange={handleInputSearch}
                        autoFocus={showDropdown}
                    />
                </Dropdown.Header>
                {filterData.length > 0 ? (
                    filterData?.map((metadataDTO) => {
                        return (
                            <Dropdown.Item
                                key={metadataDTO.id}
                                eventKey={metadataDTO.id}
                                active={selectedItem?.id === metadataDTO.id}
                            >
                                {formatTimeToHHMM(metadataDTO.timestamp)}
                            </Dropdown.Item>);
                    })) : <Dropdown.Item>No results found</Dropdown.Item>}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default VibrationDeviceTimeSelector;
