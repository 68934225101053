import {Card} from 'react-bootstrap';
import {useLocation} from 'react-router-dom';

import Layout from '../../layout/default';
import Block from '../../components/Block/Block';
import {DevicesProvider} from "./DevicesProvider";
import {format, formatDistanceToNow, isAfter, subDays} from 'date-fns';
import BranchSelector from "../branch/BranchSelector";
import {useCompanyProvider} from "../../guards/companyProvider";
import React, {useState} from "react";
import DeviceDataTable from "./DeviceDataTable";


export const objectToQueryParamString = function (obj) {
    const str = [];
    obj.keys().forEach(function (key) {
        str.push(encodeURIComponent(key) + "=" + encodeURIComponent(obj.get(key)));
    })
    return str.join("&");
}

export const formatDateToLastSeen = (date) => {
    if (!date) {
        return '-';
    }
    //console.log(date);
    const twentyFourHoursAgo = subDays(new Date(), 1);

    if (isAfter(new Date(date), twentyFourHoursAgo)) {
        return formatDistanceToNow(new Date(date), {addSuffix: true});
    } else {
        return format(new Date(date), 'dd/MM/yyyy, HH:mm');
    }
};

export const getDeviceStatusBadgeVariant = (status) => {
    switch (status?.toLowerCase()) {
        case 'waiting':
        case 'joined':
            return 'warning';
        case 'deactivated':
            return 'danger';
        default:
            return 'success';
    }
};

export const getDeviceStatusTooltip = (status) => {
    switch (status?.toLowerCase()) {
        case 'waiting':
            return 'Device is yet to join the system'
        case 'joined':
            return 'Device has joined system but yet to send data';
        case 'deactivated':
            return 'Device was deactivated due to inactivity';
        default:
            return 'Device has joined and is sending data';
    }
};

export const formatInterval = (seconds) => {
    if (seconds < 60) {
        return `${seconds} seconds`;
    } else if (seconds < 3600) {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes} minute${minutes !== 1 ? 's' : ''} ${remainingSeconds > 0 ? `${remainingSeconds} second${remainingSeconds !== 1 ? 's' : ''}` : ''}`;
    } else {
        const hours = Math.floor(seconds / 3600);
        const remainingMinutes = Math.floor((seconds % 3600) / 60);
        return `${hours} hour${hours !== 1 ? 's' : ''} ${remainingMinutes > 0 ? `${remainingMinutes} minute${remainingMinutes !== 1 ? 's' : ''}` : ''}`;
    }
}


const DeviceList = () => {
    const {company} = useCompanyProvider();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const selectedBranchId = queryParams.get('branchId') || 'all';
    const deviceSearchQueries = new Map();

    if (queryParams.get('deviceMacAddress')) {
        deviceSearchQueries.set('deviceMacAddress', queryParams.get('deviceMacAddress'));
    }

    if (queryParams.get('status')) {
        deviceSearchQueries.set('status', queryParams.get('status'));
    }

    return (
        <DevicesProvider>
            <Layout title="Users List" content="container">
                <Block.Head>
                    <Block.HeadBetween>
                        <Block.HeadContent>
                            <BranchSelector company={company}/>
                        </Block.HeadContent>
                        <Block.HeadContent>
                            <Block.Title tag="h2">Devices</Block.Title>
                        </Block.HeadContent>
                        <Block.HeadContent>
                        </Block.HeadContent>
                    </Block.HeadBetween>
                </Block.Head>

                <Block>
                    <Card className="app-table">{
                        selectedBranchId === 'all' && <Card.Body>
                            <span>Select Branch to show devices</span>
                        </Card.Body>
                    }
                        {selectedBranchId !== 'all' && <DeviceDataTable deviceSearchQueries={deviceSearchQueries}
                                                                        selectedBranchId={selectedBranchId}
                                                                        queryParams={queryParams}/>
                        }
                    </Card>
                </Block>
            </Layout>
        </DevicesProvider>
    )
}

export default DeviceList;