import {ButtonGroup, Card, Col, Container, Row} from 'react-bootstrap';

import React, {useEffect, useState} from "react";
import axiosInstance from "../../interceptor/axiosInterceptor";
import {useAuth} from "../../guards/authProvider";
import TimeSeriesChartGrid from "../../components/Chart/TimeSeriesChartGrid";
import {ChartLegend} from "../../components";
import {useLocation, useNavigate} from "react-router-dom";
import Layout from '../../layout/default';
import Block from "../../components/Block/Block";
import FaultyIndicator from "./FaultyIndicator";
import StatusBadge from "../components/StatusBadge";
import {formatDateToLastSeen, formatInterval, getDeviceStatusBadgeVariant, getDeviceStatusTooltip} from "./DeviceList";
import DeviceStatusBadge from "./DeviceStatusBadge";
import AppToast from "../../components/toast/AppToast";
import Button from "react-bootstrap/Button";
import {FaChevronLeft, FaClock} from "react-icons/fa";
import CalendarComponent from "../../components/Calendar/Calendar";
import {getTimeZoneOffset} from "./VibrationDeviceTimeSelector";

const deviceDataTimeSeriesConverter = (deviceData, device) => {
    if (deviceData && deviceData.length > 0) {
        //console.log('valid device data');
        const timeSeriesOfSensors = new Map();
        deviceData.forEach(data => {
            for (const [sensorName, value] of Object.entries(data.sensorValues)) {
                if (!timeSeriesOfSensors.has(sensorName)) {
                    timeSeriesOfSensors.set(sensorName, []);
                }
                timeSeriesOfSensors.get(sensorName).push([data.timestamp, value]);
            }
        });
        //console.log(timeSeriesOfSensors);
        return timeSeriesOfSensors;
    } else {
        const timeSeriesOfSensors = new Map();
        if (device.sensorNames) {
            device.sensorNames.forEach((sensorName) => {
                timeSeriesOfSensors.set(sensorName, []);
            });
        }

        return timeSeriesOfSensors;
    }
}

const DeviceDetails = () => {

    const getStartEndDateUTC = (now) => {
        // Ensure the input is a positive integer
        if (typeof now !== 'object') {
            throw new Error('Invalid date');
        }

        // Set the start date to the start of today in UTC
        const endDate = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 23, 59, 59));

        // Calculate the end date
        const startDate = new Date(endDate.getTime() - 24 * 60 * 60 * 1000); // Add n days in milliseconds

        // Convert dates to ISO strings
        return {
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString()
        };
    };
    const location = useLocation();
    const device = location.state?.device || [];
    const navigate = useNavigate();

    const emptyTimeseries = new Map();
    emptyTimeseries.set('x', []);

    const {userDetails} = useAuth();
    const [fromToDates, setFromToDates] = useState(undefined);
    const [timeseriesForSensors, setTimeseriesForSensors] = useState(emptyTimeseries);
    const [selectedDate, setSelectedDate] = useState(null);

    const fetchDeviceData = async () => {
        if (!fromToDates) {
            return;
        }
        await axiosInstance.get(`companies/${userDetails.companyId}/branches/${device.branchId}/devices/${device.id}/data?from=${fromToDates.from}&to=${fromToDates.to}`)
            .then((response) => {
                if (response.status === 200) {
                    setTimeseriesForSensors(deviceDataTimeSeriesConverter(response.data, device));
                } else {
                    AppToast.error('Error while fetching device data');
                }
            }).catch((error) => {
                //console.log(error);
                AppToast.error(error.message);
                setTimeseriesForSensors(deviceDataTimeSeriesConverter([], device));
            });
    }

    const load = () => {
        const queryParams = new URLSearchParams(location.search);
        const {startDate, endDate} = getStartEndDateUTC(new Date());
        const from = queryParams.get('from') || startDate;
        const to = queryParams.get('to') || endDate;
        setSelectedDate(new Date(new Date(from).getTime() + 1000));
        setFromToDates({from: from, to: to});
        setTimeseriesForSensors(emptyTimeseries);
    }

    useEffect(() => {
        fetchDeviceData();
        load();
    }, [location.search]);

    const loadDataWithStartDateAndEndTime = (startDate, endDate) => {
        const queryParams = new URLSearchParams(location.search);
        queryParams.set('from', startDate);
        queryParams.set('to', endDate);
        navigate(`?${decodeURIComponent(queryParams.toString())}`, {state: {device: device}});
    };


    const handleDateChange = (date) => {
        const {startDate, endDate} = getStartEndDateUTC(date);
        loadDataWithStartDateAndEndTime(startDate, endDate);
    };

    const onBackPressed = () => {
        const queryParams = new URLSearchParams(location.search);
        navigate(`/devices?${decodeURIComponent(queryParams.toString())}`);
    }

    return (
        <Layout title="Devices List" content="h-100 p-0 m-0">
            <Card>
                <Container fluid>
                    <Row className="justify-content-center align-items-center bg-light">
                        <div className="card-title-group flex flex-wrap align-items-center">
                            <Button variant="link" onClick={(e) => onBackPressed()}>
                                <FaChevronLeft className="btn-outline-primary"/>Back
                            </Button>
                            <div className="card-title">
                                <h5 className="title mb-0">{device.name} Details <DeviceStatusBadge classname={'m-1'}
                                                                                                    lastSeen={device.timestamp}
                                                                                                    conf={device.currentConfiguration}/>
                                </h5>
                            </div>
                            <ChartLegend.Group className="gap gx-3 align-items-center p-1">
                                <div className="gap-col">
                                    <ButtonGroup className="border">
                                        <CalendarComponent hasTimePicker={true} selectedDate={selectedDate}
                                                           onDateChange={handleDateChange}/>
                                        <Button size="sm" variant="outline-primary"
                                                disabled
                                                className="d-flex align-items-center" style={{
                                            borderTopLeftRadius: '0px',
                                            borderLeftWidth: '0px',
                                            borderStartWidth: '0px',
                                            borderBottomLeftRadius: '0px',
                                        }}>
                                            <FaClock className="me-1"/>
                                            UTC{getTimeZoneOffset()}
                                        </Button>
                                    </ButtonGroup>
                                </div>
                            </ChartLegend.Group>
                        </div>
                    </Row>
                    <Row>
                        <Col>

                            <Card.Body className="overflow-scroll">

                                <Block className="mt-1">
                                    <Row>
                                        <Col sm={12} xxl={6}>
                                            <div className="bio-block">
                                                <ul className="list-group list-group-borderless small">
                                                    <li className="list-group-item">
                                                        <span
                                                            className="title fw-medium w-40 d-inline-block">Name</span>
                                                        <span className="text">{device.name}</span>
                                                    </li>
                                                    <li className="list-group-item">
                                                    <span
                                                        className="title fw-medium w-40 d-inline-block">Device Eui</span>
                                                        <span className="text">{device.deviceEui}</span>
                                                    </li>
                                                    <li className="list-group-item">
                                                    <span
                                                        className="title fw-medium w-40 d-inline-block">Is Faulty</span>
                                                        <FaultyIndicator isFaulty={device.hasDeviation}/>
                                                    </li>
                                                    <li className="list-group-item">
                                                    <span
                                                        className="title fw-medium w-40 d-inline-block">Activation Status</span>
                                                        <StatusBadge status={device.deviceStatus}
                                                                     getVariant={getDeviceStatusBadgeVariant}
                                                                     tooltipText={getDeviceStatusTooltip}/>
                                                    </li>

                                                </ul>
                                            </div>
                                        </Col>
                                        <Col sm={12} xxl={6}>
                                            <div className="bio-block">

                                                <ul className="list-group list-group-borderless small">
                                                    <li className="list-group-item">
                                                    <span
                                                        className="title fw-medium w-40 d-inline-block">Last Seen</span>
                                                        <span
                                                            className="text">{formatDateToLastSeen(device.createdAt)}</span>
                                                    </li>
                                                    <li className="list-group-item">
                                                    <span
                                                        className="title fw-medium w-40 d-inline-block">Read Interval</span>
                                                        <span
                                                            className="text">{formatInterval(device.readPeriod || device.currentConfiguration?.readPeriod)}</span>
                                                    </li>
                                                    <li className="list-group-item">
                                                    <span
                                                        className="title fw-medium w-40 d-inline-block">Limit Min</span>
                                                        <span
                                                            className="text">{device.currentConfiguration?.temperatureLimitMin}</span>
                                                    </li>
                                                    <li className="list-group-item">
                                                    <span
                                                        className="title fw-medium w-40 d-inline-block">Limit Max</span>
                                                        <span
                                                            className="text">{device.currentConfiguration?.temperatureLimitMax}</span>
                                                    </li>

                                                </ul>
                                            </div>
                                        </Col>
                                    </Row>
                                </Block>
                                <div className="nk-chart-analytics-audience-overview mt-3 h-100">
                                    <TimeSeriesChartGrid timeSeriesDataMap={timeseriesForSensors}/>
                                </div>
                            </Card.Body>

                        </Col>
                    </Row>
                </Container>
            </Card>
        </Layout>
    );
}

export default DeviceDetails;