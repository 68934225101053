import classNames from 'classnames';
import {Dropdown} from 'react-bootstrap';

import {
    CustomDropdownMenu,
    CustomDropdownToggle,
    Icon,
    Image,
    LinkList,
    LinkListItem,
    Media,
    MediaGroup,
    MediaText
} from '../../../components';

import ToggleSidebar from '../Toggle/Sidebar'

import {useLayout, useLayoutUpdate} from '../LayoutProvider'
import {useAuth} from "../../../guards/authProvider";
import CompanySelector from "../../../pages/companies/CompanySelector";
import {useLocation} from "react-router-dom";
import {useCompanyProvider} from "../../../guards/companyProvider";


function QuickNav({className, ...props}) {
    const compClass = classNames({
        "nk-quick-nav": true,
        [className]: className,
    })
    return (
        <ul className={compClass}>{props.children}</ul>
    )
}

function QuickNavItem({className, ...props}) {
    const compClass = classNames({
        "d-inline-flex": true,
        [className]: className,
    })
    return (
        <li className={compClass}>{props.children}</li>
    )
}

function Header() {
    const {logout, user} = useAuth();

    const layout = useLayout();
    const layoutUpdate = useLayoutUpdate();

    const compClass = classNames({
        "nk-header nk-header-fixed": true,
        [`is-${layout.headerVariant}`]: layout.headerVariant,
    });

    const navClass = classNames({
        "nk-header-menu nk-navbar": true,
        "navbar-active": layout.headerActive,
        // eslint-disable-next-line
        "navbar-mobile": layout.headerTransition || eval(`layout.breaks.${layout.headerCollapse}`) > window.innerWidth,
    });

    const getUsersName = () => {
        if (user) {
            return user.firstName + ' ' + user.lastName;
        } else return '';
    }
    const location = useLocation();

    const queryParams = decodeURIComponent(new URLSearchParams(location.search).toString());

    return (
        <>
            <div className={compClass}>
                <div className="container-fluid">
                    <div className="nk-header-wrap">
                        <div className="nk-header-logo">
                            <ToggleSidebar variant="zoom" icon='menu'/>
                        </div>
                        {layout.headerActive &&
                            <div className="navbar-overlay" onClick={layoutUpdate.headerMobile}></div>}
                        <nav className={navClass}>
                        </nav>
                        <div className="nk-header-tools d-flex">
                            {user?.userAuthority === 'ADMIN' &&
                                <div className="me-2">
                                    <CompanySelector/>
                                </div>
                            }
                            <QuickNav>
                                <Dropdown as={QuickNavItem}>
                                    <Dropdown.Toggle bsPrefix as={CustomDropdownToggle}>

                                        <div className="d-inline-flex d-sm-none">
                                            <Media shape="circle" size="md">
                                                <Image src='/images/avatar/c.jpg' staticImage thumbnail/>
                                            </Media>
                                        </div>
                                        <div className="d-none d-sm-flex">
                                            <Media shape="circle">
                                                <Image src='/images/avatar/c.jpg' staticImage thumbnail/>
                                            </Media>
                                        </div>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdown-menu-md" as={CustomDropdownMenu}>
                                        <div
                                            className="dropdown-content dropdown-content-x-lg py-3 border-bottom border-light">
                                            <MediaGroup>
                                                <Media size="xl" shape="circle">
                                                    <Image src='/images/avatar/c.jpg' staticImage thumbnail/>
                                                </Media>
                                                <MediaText>
                                                    <div
                                                        className="lead-text">{getUsersName()}</div>
                                                    <span className="sub-text">{user?.username}</span>
                                                </MediaText>
                                            </MediaGroup>
                                        </div>
                                        <div
                                            className="dropdown-content dropdown-content-x-lg py-3 border-bottom border-light">
                                            <LinkList>
                                                <LinkListItem to={`/profile?${queryParams}`}><Icon
                                                    name="user"></Icon><span>My Profile</span></LinkListItem>
                                            </LinkList>
                                        </div>
                                        <div className="dropdown-content dropdown-content-x-lg py-3">
                                            <LinkList>
                                                <LinkListItem action={logout ? logout : () => {
                                                }}><Icon
                                                    name="signout"></Icon><span>Log Out</span></LinkListItem>
                                            </LinkList>
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </QuickNav>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header