import React, {useEffect, useState} from 'react';
import DataTable from 'react-data-table-component';
import axiosInstance from "../../interceptor/axiosInterceptor";

const AppDataTable = ({url, columns = [], title, onDataLoaded, forceRefresh = false}) => {

    const [data, setData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const fetchData = async (page, rowsPerPage) => {
        setLoading(true);

        try {
            const response = await axiosInstance.get(url, {
                params: {
                    page: page, // Spring Pageable is 0-indexed
                    size: rowsPerPage,
                },
            });

            if (onDataLoaded) {
                onDataLoaded(response.data.content);
            }
            setData(response.data.content); // Extract data from content
            setTotalRows(response.data.totalElements); // Set total number of rows
        } catch (error) {
            console.error('Failed to fetch devices:', error);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        fetchData(page, rowsPerPage);
    }, [url, page, rowsPerPage, forceRefresh]);

    if (!url || !columns || !columns.length) {
        //console.log(JSON.stringify(url));
        //console.log(JSON.stringify(columns));
        return <div>Loading...</div>;
    }

    const handlePageChange = (page) => {
        setPage(page);
    };

    const handleRowsPerPageChange = (newRowsPerPage) => {
        setRowsPerPage(newRowsPerPage);
        setPage(1); // Reset to the first page
    };

    return (
        <DataTable
            columns={columns}
            data={data}
            progressPending={loading}
            noDataComponent={<div className="p-2">There are no records found.</div>}
            persistTableHead
            pagination
            responsive
            paginationServer
            paginationTotalRows={totalRows}
            paginationDefaultPage={page}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
        />
    );
};

export default AppDataTable;
