// AuthContext.js
import React, {createContext, useContext, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {decodeJWT} from "./jwtDecoder";
import axiosInstance, {initializeAxios} from "../interceptor/axiosInterceptor";
import {useProgress} from "../progress/ProgressProvider";

const AuthContext = createContext(null);

export const AuthProvider = ({children}) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [userDetails, setUserDetails] = useState(null);
    const [user, setUser] = useState(null);
    const [token, setToken] = useState(null);
    const progressProvider = useProgress();
    initializeAxios(progressProvider);


    const navigate = useNavigate();
    const location = useLocation();

    const updateUserDetails = (token) => {
        const decodedUserDetails = decodeJWT(token);
        if (decodedUserDetails) {
            setUserDetails(decodedUserDetails);
        }
    };

    const setCompanyId = (companyId) => {
        const queryParams = new URLSearchParams(location.search);
        queryParams.set("companyId", companyId);
        setUserDetails({...userDetails, companyId});
        navigate(`${location.pathname}?${decodeURIComponent(queryParams.toString())}`);
    }

    const getUser = () => {
        return axiosInstance.get(`/companies/${userDetails.companyId}/users/${userDetails.userId}`).then((response) => {
            //console.log(response);
            if (response.status === 200) {
                setUser(response.data);
                //console.log(response.data);
                return response.data;
            } else if (response.status === 401) {
                setIsAuthenticated(false);
            }
            return Promise.reject('Error while getting user');
        }).catch(error => {
            //console.log(error);
            return Promise.reject(error);
        });
    }

    const login = (token) => {
        if (token) {
            setToken(token);
            localStorage.setItem('token', token);
            updateUserDetails(token);
            setIsAuthenticated(true);
        }
    };

    const logout = (redirect = true) => {
        localStorage.removeItem('token');
        setIsAuthenticated(false);
        setUserDetails(null);
        setUser(null);
        setToken(null);
        if (redirect) {
            navigate('/login');
        }
    };

    if (!token) {
        login(localStorage.getItem('token'));
    }

    return (
        <AuthContext.Provider value={{isAuthenticated, userDetails, setCompanyId, user, getUser, login, logout}}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
