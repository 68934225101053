import {
    Button,
    Card,
    Col,
    Dropdown,
    DropdownDivider,
    FormControl,
    FormGroup,
    OverlayTrigger,
    Row,
    Tooltip
} from "react-bootstrap";
import React, {useEffect, useRef} from "react";
import {FaFilter} from "react-icons/fa";

import './FormFilter.css'

const FormFilter = ({name, value, onSearch, type, placeholder, enumItems}) => {
    const filterRef = useRef(null);
    const [searchText, setSearchText] = React.useState("");
    const [show, setShow] = React.useState(false);

    useEffect(() => {
        if (value) {
            setSearchText(value);
        }
    }, [value]);
    const handleSubmit = () => {
        setShow(false);
        if (onSearch) {
            onSearch(searchText);
        }
    }

    const handleCancel = () => {
        setShow(false);
        if (onSearch) {
            setSearchText("");
            onSearch('');
        }
    }

    const onSearchTextChange = (text) => {
        setSearchText(text);
    }

    const filterForm = (props) => {
        return (
            <Tooltip {...props} className="bg-transparent">
                <Card className="border-0">
                    <Card.Body>
                        <Row>
                            <Col>
                                {type === 'text' &&
                                    <FormGroup className="form-group">
                                        <FormControl
                                            autoFocus
                                            placeholder={placeholder}
                                            id="searchByMacAddress"
                                            type="text"
                                            name="searchByMacAddress"
                                            size="sm"
                                            value={searchText}
                                            onChange={(e) => onSearchTextChange(e.target.value)}
                                        />

                                    </FormGroup>
                                }
                                {
                                    type === 'enum' && (
                                        <Dropdown drop="down" onSelect={onSearchTextChange} value={searchText}>
                                            <Dropdown.Toggle
                                                variant="outline-secondary">{searchText ? searchText : placeholder}</Dropdown.Toggle>
                                            <Dropdown.Menu className="justify-content-center">
                                                {enumItems.map((item) => {
                                                    return (
                                                        <Dropdown.Item eventKey={item} key={item}>{item} </Dropdown.Item>);
                                                })}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    )
                                }
                            </Col>
                        </Row>
                        <DropdownDivider/>
                        <Row className="mt-2">
                            <Col>
                                <Button size="sm" variant="outline-secondary" onClick={handleCancel}>Clear</Button>
                            </Col>
                            <Col>
                                <Button size="sm" className="ms-3" type="submit" onClick={handleSubmit}>Apply</Button>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Tooltip>
        );
    }

    return (

        <div style={{display: "flex", alignItems: 'center'}}>{name}
            <OverlayTrigger show={show} placement="bottom" overlay={filterForm} trigger="click" rootClose>
                <Button ref={filterRef}
                        variant="link" className="p-0 m-1" onClick={(e) => {
                    e.preventDefault();
                    setShow(!show);
                }}>
                    <FaFilter color={value ? 'primary' : 'gray'} size={10}/>
                </Button>
            </OverlayTrigger>
        </div>

    );
}

export default FormFilter;