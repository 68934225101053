import React from 'react'
import {Link} from 'react-router-dom'

function LinkListItem({className, linkClassName, to, action, href, ...props}) {
    //console.log(action);
    return (
        <li className={className} onClick={action ? (e) => action() : (e) => e.stopPropagation()}>
            {to && <Link className={linkClassName} to={to}>{props.children}</Link>}
            {action && <Link className={linkClassName} to={to}>{props.children}</Link>}
            {href && <a className={linkClassName} href={href}>{props.children}</a>}
        </li>
    )
}

export default LinkListItem