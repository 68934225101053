import React from 'react';
import TimeSeriesChart from './TimeSeriesChart';
import './TimeSeriesChartGrid.css';
import TimeSeriesAccelerationChart from "./TimeSeriesAccelerationChart";
import {ButtonGroup, Col, Row, ToggleButton} from "react-bootstrap";
import FrequencyDomainAccelerationChart from "./FrequencyDomainAccelerationChart";
import FrequencyDomainVelocityChart from "./FrequencyDomainVelocityChart";

const ChartItem = ({children}) => {
    return (
        <Col md={12} lg={6} style={{marginBottom: '16px', paddingBottom: '16px'}}>
            <div className="chart-item">
                {children}
            </div>
        </Col>
    );
}
const TimeSeriesGrid = ({
                            timeSeriesDataMap,
                            frequencySeriesDataMap,
                            velocitySeriesDataMap,
                            sensorType,
                            colors,
                            onChartExpanded
                        }) => {
    const [selectChartType, setSelectedChartType] = React.useState(0);

    const seriesDataObj = (sensorName, data) => {
        const seriesObj = {};
        seriesObj[sensorName] = data;
        return seriesObj;
    }

    const getVibrationSensorChart = () => {
        if (selectChartType === 1) {
            return (
                <Row>
                    <ChartItem key={'xyz'}>
                        <FrequencyDomainAccelerationChart
                            title={'Frequency Domain Acceleration - x, y, z'}
                            frequencySeriesData={Object.fromEntries(frequencySeriesDataMap)}
                            colors={colors} onExpanded={onChartExpanded}/>
                    </ChartItem>
                    {Array.from(frequencySeriesDataMap.entries()).map(([s, data]) => (
                        <ChartItem key={s}>
                            <FrequencyDomainAccelerationChart title={'Frequency Domain Acceleration -' + s}
                                                              frequencySeriesData={seriesDataObj(s, data)}
                                                              colors={colors}
                                                              onExpanded={onChartExpanded}
                            />
                        </ChartItem>
                    ))}
                </Row>
            );
        } else if (selectChartType === 2) {
            return (
                <Row>
                    <ChartItem key={'xyz'}>
                        <FrequencyDomainVelocityChart
                            title={'Frequency Domain Velocity - x, y, z'}
                            velocitySeriesData={Object.fromEntries(velocitySeriesDataMap)}
                            colors={colors} onExpanded={onChartExpanded}/>
                    </ChartItem>
                    {Array.from(velocitySeriesDataMap.entries()).map(([s, data]) => (
                        <ChartItem key={s}>
                            <FrequencyDomainVelocityChart
                                title={'Frequency Domain Velocity -' + s}
                                velocitySeriesData={seriesDataObj(s, data)}
                                colors={colors}
                                onExpanded={onChartExpanded}
                            />
                        </ChartItem>
                    ))}
                    <Col>
                        <p className="sm">Frequency Domain Velocity is derived from acceleration FFT</p>
                    </Col>
                </Row>
            );
        }
        return (
            <Row>
                <ChartItem key={'xyz'}>
                    <TimeSeriesAccelerationChart
                        title={'Time Domain Acceleration - x, y, z'}
                        timeSeriesData={Object.fromEntries(timeSeriesDataMap)}
                        colors={colors} onExpanded={onChartExpanded}/>
                </ChartItem>
                {Array.from(timeSeriesDataMap.entries()).map(([s, data]) => (
                    <ChartItem key={s}>
                        <TimeSeriesAccelerationChart title={'Time Domain Acceleration -' + s}
                                                     timeSeriesData={seriesDataObj(s, data)}
                                                     colors={colors}
                                                     onExpanded={onChartExpanded}
                        />
                    </ChartItem>
                ))}
            </Row>
        );
    }
    if (sensorType === 'VIBRATION_SENSOR') {
        return (
            <>
                <Row className="justify-content-end">
                    <div className="d-flex align-items-center justify-content-end pb-1">
                        <ButtonGroup toggle="true" className="z-0">
                            <ToggleButton
                                size="sm"
                                type="radio"
                                variant="outline-primary"
                                name="toggle"
                                value="time"
                                checked={selectChartType === 0}
                                onClick={(e) => setSelectedChartType(0)}
                                id="time">
                                Acceleration(Time)
                            </ToggleButton>
                            <ToggleButton
                                size="sm"
                                id="frequency"
                                type="radio"
                                variant="outline-primary"
                                name="toggle"
                                value="frequency"
                                checked={selectChartType === 1}
                                onClick={(e) => setSelectedChartType(1)}
                            >
                                Acceleration FFT
                            </ToggleButton>
                            <ToggleButton
                                size="sm"
                                id="frequency"
                                type="radio"
                                variant="outline-primary"
                                name="toggle"
                                value="frequency"
                                checked={selectChartType === 2}
                                onClick={(e) => setSelectedChartType(2)}
                            >
                                Velocity FFT
                            </ToggleButton>
                        </ButtonGroup>
                    </div>
                    {
                        getVibrationSensorChart()
                    }
                </Row>
            </>
        );
    } else {
        return (
            <div className="chart-grid">
                {Array.from(timeSeriesDataMap.entries()).map(([title, data]) => (
                    <div className="chart-item" key={title}>
                        <TimeSeriesChart title={title} timeSeriesData={data}/>
                    </div>
                ))}
            </div>
        );
    }
};

export default TimeSeriesGrid;