// DateFormat.js
import React from 'react';
import {format} from 'date-fns';

const DateFormat = ({isoDateString, formatString}) => {
    const formatDate = (dateString) => {
        try {
            return format(new Date(dateString), formatString);
        } catch (err) {
            return '';
        }
    };

    return (
        <span>
            {formatDate(isoDateString)}
        </span>
    );
};

export default DateFormat;
