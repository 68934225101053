// ProgressContext.js
import React, {createContext, useContext, useState} from 'react';
import AppProgressBar from "./AppProgressBar";

const ProgressContext = createContext(null);

export const useProgress = () => useContext(ProgressContext);

export const ProgressProvider = ({children}) => {
    const [loadingCount, setLoadingCount] = useState(0);
    const [progress, setProgress] = useState(0);

    const updateProgress = (percentage) => {
    }//setProgress(percentage);
    const startLoading = () => {
    }//setLoadingCount(prev => prev + 1);
    const stopLoading = () => {
    }//setLoadingCount(prev => Math.max(prev - 1, 0));

    const isLoading = loadingCount > 0;

    return (
        <ProgressContext.Provider value={{startLoading, stopLoading, updateProgress, progress}}>
            {isLoading && <AppProgressBar/>}
            {children}
        </ProgressContext.Provider>
    );
};
