// UserDetails.js
export class UserDetails {
    constructor(subject, userId, companyId, branchIds, userAuthority, expiresAt, issuer) {
        this.subject = subject;              // Corresponds to "sub" in JWT
        this.userId = userId;                // Corresponds to "detail.userId" in JWT
        this.companyId = companyId;          // Corresponds to "detail.companyId" in JWT
        this.branchIds = branchIds;          // Corresponds to "detail.branchIds" in JWT
        this.userAuthority = userAuthority;  // Corresponds to "detail.userAuthority" in JWT
        this.expiresAt = expiresAt;          // Corresponds to "exp" in JWT
        this.issuer = issuer;                // Corresponds to "iss" in JWT
    }
}

export const decodeJWT = (token) => {
    if (!token) return null;

    const parts = token.split('.');

    if (parts.length !== 3) {
        throw new Error('Invalid JWT token');
    }

    try {
        const payload = JSON.parse(atob(parts[1]));
        return new UserDetails(
            payload.sub,
            payload.detail.userId,
            payload.detail.companyId,
            payload.detail.branchIds,
            payload.detail.userAuthority,
            payload.exp,
            payload.iss
        );
    } catch (e) {
        console.error('Failed to decode JWT payload', e);
        return null;
    }
};
