import React, {useEffect, useRef, useState} from 'react';
import {MapContainer, Marker, TileLayer, useMapEvents} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import {UpdateMapCenter} from "../../components/map/MapWithLocations";

// Fix for missing marker icon when using Webpack
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
    iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
});

const LocationSelector = ({initialLocation, onLocationSelect}) => {
    const [position, setPosition] = useState(null);
    const mapRef = useRef();

    useEffect(() => {
        if (initialLocation) {
            setPosition(initialLocation);
        }
    }, [initialLocation]);

    const LocationMarker = () => {
        useMapEvents({
            click(e) {
                const {lat, lng} = e.latlng;
                setPosition([lat, lng]);
                // onLocationSelect({lat, lng});  // Pass the selected location to the parent component
                onLocationSelect('latitude', lat);
                onLocationSelect('longitude', lng);
            },
        });

        return position?.lat ? <Marker position={position}/> : null;
    };

    return (
        <MapContainer
            ref={mapRef}
            center={position?.lat ? position : [45.596806,-73.340286]} // Initial center coordinates
            zoom={13}                // Initial zoom level
            style={{height: '400px', width: '100%'}}  // Map container styles
        >
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"  // Tile provider
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            />
            <LocationMarker/>
            <UpdateMapCenter center={initialLocation?.lat ? initialLocation : [45.596806,-73.340286]}/>

        </MapContainer>
    );
};

export default LocationSelector;
