import {Button, Modal} from "react-bootstrap";
import React, {useEffect} from "react";
import TimeSeriesAccelerationChart from "../../components/Chart/TimeSeriesAccelerationChart";
import FrequencySeriesAccelerationChart from "../../components/Chart/FrequencyDomainAccelerationChart";
import FrequencyDomainVelocityChart from "../../components/Chart/FrequencyDomainVelocityChart";

const ChartModal = ({timeSeriesData, frequencySeriesData, velocitySeriesData, title, colors}) => {
    const [show, setShow] = React.useState(false);

    useEffect(() => {
        setShow(!!timeSeriesData || !!frequencySeriesData || !!velocitySeriesData);
    }, [timeSeriesData, frequencySeriesData, velocitySeriesData]);
    return (
        <Modal size="xl" show={show} centered backdrop="static">
            <Modal.Body>
                {timeSeriesData && <TimeSeriesAccelerationChart title={title}
                                                                timeSeriesData={timeSeriesData}
                                                                colors={colors}
                />}
                {
                    frequencySeriesData && <FrequencySeriesAccelerationChart title={title}
                                                                             frequencySeriesData={frequencySeriesData}
                                                                             colors={colors}/>
                }
                {
                    velocitySeriesData && <FrequencyDomainVelocityChart title={title}
                                                                        velocitySeriesData={velocitySeriesData}
                                                                        colors={colors}/>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={(e) => setShow(false)}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ChartModal;