import {Link} from 'react-router-dom';

function Logo() {
    return (
        <Link to="/" className="logo-link">
            <div className="logo-wrap">
                <div className="logo-svg">
                    <img src="/images/logo.png" alt="logo" className="logo-text"/>
                    <img className="logo-monogram" src="/logo512.png"
                         alt="logo"/>
                </div>
            </div>
        </Link>
    )
}

export default Logo;
