import React, {useEffect, useRef, useState} from 'react';
import {Line} from 'react-chartjs-2';
import {
    Chart as ChartJS,
    Decimation,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    TimeScale,
    Title,
    Tooltip
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import {format} from "date-fns";
import zoomPlugin from 'chartjs-plugin-zoom';
import {Card, Col, Container, Row} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {FaExpand, FaRedo} from "react-icons/fa";

// Register the necessary components from Chart.js
ChartJS.register(
    LineElement, PointElement, LinearScale, Title, Tooltip, Legend, TimeScale, Decimation,
    TimeScale,
    Tooltip,
    Legend,
    zoomPlugin
);

const TimeSeriesAccelerationChart = ({timeSeriesData, title, colors, onExpanded}) => {
    const chartRef = useRef(null);

    const [chartData, setChartData] = useState({
        options: {},
        data: []
    });

    // Sample data with milliseconds steps
    useEffect(() => {
        if (!(Object.entries(timeSeriesData).length === 0 || Object.keys(timeSeriesData).filter(key => timeSeriesData[key].length === 0).length > 0)) {
            loadChartData();

        }
    }, [timeSeriesData]);

    if (Object.entries(timeSeriesData).length === 0 || Object.keys(timeSeriesData).filter(key => timeSeriesData[key].length === 0).length > 0) {
        const fixedLabels = ['', '100', '200', '300', '400', '500', '600', '700', '800', '900', ''];
        const chartData = {
            fixedLabels,
            datasets: [
                {
                    label: title,
                    data: [],
                    borderColor: 'rgba(75, 192, 192, 1)',
                    backgroundColor: 'rgba(75, 192, 192, 0.2)',
                    fill: true,
                },
            ],
        };
        return (<div>
            <h2>{title}</h2>
            <Line data={chartData}/>
        </div>);
    }

    const loadChartData = () => {
        const dataset = [];
        let startDate;
        let minTimestamp = 0;
        let maxTimestamp = 0;

        Object.keys(timeSeriesData).forEach(key => {
            startDate = timeSeriesData[key][0][0];
            const labels = timeSeriesData[key].map(dateStr => dateStr[0].getTime());
            const data = timeSeriesData[key].map(dateStr => dateStr[1]);
            minTimestamp = Math.min(...labels);
            maxTimestamp = Math.max(...labels);
            dataset.push({
                label: key,
                data: data.map((value, index) => ({
                    x: labels[index],
                    y: value,
                })), // Random data points for demonstration
                borderColor: colors[key],
                borderWidth: 1,
                pointRadius: 0.7, // To remove points on the line
                tension: 0,
            },);

        });

        const fixedLabels = ['', '100', '200', '300', '400', '500', '600', '700', '800', '900', ''];

        const chartData = {
            labels: fixedLabels,
            datasets: dataset,
        };


        const options = {
            scales: {
                x: {
                    type: 'time',
                    unit: 'millisecond', // Use milliseconds as the unit for the x-axis
                    stepSize: 10,
                    time: {
                        displayFormats: {
                            millisecond: 'SSS', // Format to show milliseconds
                        },
                    },
                    min: minTimestamp,
                    max: maxTimestamp,
                    ticks: {
                        source: 'data',
                        maxRotation: 0,
                        autoSkip: true,
                        maxTicksLimit: 4,
                        callback: function (value, index, values) {
                            const val = (values[index].value - minTimestamp) / 1000;
                            return `${val}`;
                        },
                    },
                    title: {
                        display: true,
                        text: 'Time (milliseconds)',
                    },
                },
                y: {
                    title: {
                        display: true,
                        text: 'Vibration (G)',
                    },
                    beginAtZero: true,
                },
            },
            plugins: {
                legend: {
                    display: true,
                    position: 'bottom',
                },
                tooltip: {
                    mode: 'index',
                    intersect: false,
                    callbacks: {
                        title: (tooltipItems) => {
                            const microsecondsGap = (tooltipItems[0].raw.x - minTimestamp);
                            const isoString = new Date((startDate.getTime() / 1000) + microsecondsGap / 1000);
                            return `${format(isoString, 'HH:mm:ss.SSS')}${microsecondsGap % 1000}`; // Format date for tooltip
                        },
                        labels: (tooltipItems) => {
                            return {x: tooltipItems[0].raw.x, y: tooltipItems[0].raw.y, z: tooltipItems[0].raw.z};
                        }
                    },
                },
                zoom: {
                    zoom: {
                        limits: {
                            x: {min: 10, max: 1000},
                        },
                        speed: 0.1,
                        threshold: 10,
                        mode: 'x',
                        drag: {
                            enabled: true,
                        }
                    },
                },
            },
        };

        setChartData({options: options, data: chartData})
    }


    const handleResetZoom = () => {
        if (chartRef.current) {
            chartRef.current.resetZoom();
        }
    };

    if (chartData.data.length === 0) {
        return (<div>
            <h2>{title}</h2>
            <Card.Body></Card.Body>
        </div>);
    }
    return (
        <Container fluid>
            <Row className="align-items-center">
                <Col lg={onExpanded ? 8 : 10} sm={onExpanded ? 8 : 10}>
                    <h4 className="p-0 m-0">{title}</h4>
                </Col>
                <Col lg={onExpanded ? 4 : 2} sm={onExpanded ? 4 : 2}>
                    <div className="d-flex align-items-end justify-content-end me-1">
                        <Button variant="link" onClick={handleResetZoom}>
                            <FaRedo className="text-secondary"/>
                        </Button>
                        {onExpanded &&
                            <Button variant="link"
                                    onClick={(e) => onExpanded(title, timeSeriesData, undefined, undefined, colors)}>
                                <FaExpand className="text-secondary"/>
                            </Button>}
                    </div>
                </Col>
            </Row>
            <Line ref={chartRef} data={chartData.data} options={chartData.options}/>
        </Container>
    );
};

export default TimeSeriesAccelerationChart;
