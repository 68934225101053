// ProtectedRoute.js
import React from 'react';
import {Navigate} from 'react-router-dom';
import {useAuth} from "./authProvider";

const ProtectedRoute = ({children}) => {
    const {isAuthenticated} = useAuth();
    if (!isAuthenticated) {
        //console.log('ProtectedRoute is redirecting to login..');
        return <Navigate to="/login"/>;
    }
    return children;
};

export default ProtectedRoute;
