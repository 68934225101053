import React, {useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useCompanyProvider} from "../../guards/companyProvider";
import {useAuth} from "../../guards/authProvider";
import {Dropdown} from "react-bootstrap";
import AppToast from "../../components/toast/AppToast";

const CompanySelector = () => {
    const {userDetails, setCompanyId} = useAuth();
    const {companyList, getCompanyList, getCompany} = useCompanyProvider();
    const navigate = useNavigate();
    const location = useLocation();
    let companyIdMap = {};
    if (companyList) {
        companyList.forEach((item) => {
            companyIdMap[item.id] = item;
        });
    }

    useEffect(() => {
        if (!companyList) {
            getCompanyList().then((data) => {
                companyIdMap = {};
                data.forEach((item) => {
                    companyIdMap[item.id] = item;
                });
                if (data.length > 0) {
                    setCompanyId(data[0].id);
                    getCompany(data[0].id);
                }
            }).catch((err) => {
                AppToast.error(err.message);
                Promise.reject(err);
            })
        }
    }, [userDetails]);

    // Extract query parameter from the URL
    const queryParams = new URLSearchParams(location.search);
    const selectedFilter = queryParams.get('companyId') || 'all';

    // Handle dropdown selection
    const handleFilterChange = (event) => {
        const filterValue = event;
        // Update the URL with the selected filter
        if (filterValue) {
            queryParams.set('companyId', filterValue);
            setCompanyId(filterValue);
            getCompany(filterValue);
        } else {
            queryParams.delete('companyId');
        }
        navigate(`${location.pathname}?${decodeURIComponent(queryParams.toString())}`);
    };

    return (
        <div>
            <Dropdown value={selectedFilter} onSelect={handleFilterChange}>
                <Dropdown.Toggle variant="outline-primary"
                                 key="select-company">{selectedFilter === 'all' ? 'Select Company' : companyIdMap[selectedFilter] === undefined ? "" : companyIdMap[selectedFilter].name}</Dropdown.Toggle>
                <Dropdown.Menu>
                    {companyList?.map((company) => {
                        return (<Dropdown.Item key={company.id} eventKey={company.id}>{company.name}</Dropdown.Item>)
                    })}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
};

export default CompanySelector;
