import axiosInstance from "../../interceptor/axiosInterceptor";
import {createContext, useContext, useState} from "react";

const DeviceContext = createContext(null);

export const DevicesProvider = ({children}) => {
    //console.log('DevicesProvider');
    const [devices, setDevices] = useState(null);
    const getDevicesForBranch = async (companyId, branchId) => {
        //console.log("getDevicesForBranch", companyId, branchId);
        await axiosInstance.get(`companies/${companyId}/branches/${branchId}/devices`).then((response) => {
            if (response.status === 200) {
                //console.log(response.data);
                setDevices({...devices, [branchId]: response.data});
                return response.data;
            }
            return Promise.reject('Error while getting devices');
        }).catch((error) => {
            //console.log(error);
            return Promise.reject(error);
        });
    }
    return <DeviceContext.Provider value={{devices, getDevicesForBranch}}>{children}</DeviceContext.Provider>;
}

export const useDeviceProvider = () => useContext(DeviceContext);
