import React, {createContext, useContext, useState} from "react";
import axiosInstance from "../../interceptor/axiosInterceptor";

const DashboardContext = createContext(null);


export const DashboardProvider = ({children}) => {
    const [dashboardDto, setDashboardDto] = useState(undefined);

    const getDashboard = async () => {
        await axiosInstance.get(`/users/me/dashboard`).then((response) => {
            if (response.status === 200) {
                setDashboardDto(response.data);
                return response.data;
            }
            return Promise.reject('Error while getting company');
        }).catch(error => {
            console.log(error);
            return Promise.reject(error);
        });
    }


    return (
        <DashboardContext.Provider value={{dashboardDto, getDashboard}}>
            {children}
        </DashboardContext.Provider>
    );
}

export const useDashboardProvider = () => useContext(DashboardContext);
