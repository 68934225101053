import React, {useEffect, useState} from 'react';
import {Button, Form, Modal, Spinner} from 'react-bootstrap';
import axiosInstance from "../../interceptor/axiosInterceptor";
import {useAuth} from "../../guards/authProvider";

const EditDeviceModal = ({show, onHide, device}) => {
    const {userDetails} = useAuth();

    const [deviceName, setDeviceName] = useState('');
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        setDeviceName(device?.name || '');
    }, [device]);
    const handleSave = async () => {
        setSaving(true);
        const updatedDevice = await axiosInstance.put(`/companies/${userDetails.companyId}/branches/${device.branchId}/devices/${device.id}`, {name: deviceName});
        setSaving(false);
        onHide(updatedDevice);
    };

    return (
        <Modal show={show} onHide={onHide} centered backdrop="static" animation>
            <Modal.Header closeButton>
                <Modal.Title>Edit Device - {device?.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        handleSave().finally(() => {
                        });
                    }
                }}>
                    <Form.Group controlId="formDeviceName">
                        <Form.Label>Device Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter new device name"
                            autoFocus
                            value={deviceName}
                            onChange={(e) => setDeviceName(e.target.value)}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={(e) => onHide(undefined)}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleSave}>
                    {saving ?
                        (<Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>) : 'Save Changes'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditDeviceModal;
