import React, {useEffect, useRef, useState} from 'react';
import {Line} from 'react-chartjs-2';
import {Chart as ChartJS, Decimation, Legend, LinearScale, LineElement, PointElement, Title, Tooltip} from 'chart.js';
import 'chartjs-adapter-date-fns';
import zoomPlugin from 'chartjs-plugin-zoom';
import {Card, Col, Container, Row} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {FaExpand, FaRedo} from "react-icons/fa";

// Register the necessary components from Chart.js
ChartJS.register(
    LineElement, PointElement, LinearScale, Title, Tooltip, Legend, Decimation, zoomPlugin
);

const FrequencySeriesVelocityChart = ({velocitySeriesData, title, colors, onExpanded}) => {
    const chartRef = useRef(null);

    const [chartData, setChartData] = useState({
        options: {},
        data: []
    });

    useEffect(() => {
        if (!(Object.entries(velocitySeriesData).length === 0 || Object.keys(velocitySeriesData).filter(key => velocitySeriesData[key].length === 0).length > 0)) {
            loadChartData();
        }
    }, [velocitySeriesData]);

    if (Object.entries(velocitySeriesData).length === 0 || Object.keys(velocitySeriesData).filter(key => velocitySeriesData[key].length === 0).length > 0) {
        const fixedLabels = ['', '1000', '2000', '3000', '4000', '5000', '6000', ''];
        const chartData = {
            fixedLabels,
            datasets: [
                {
                    label: title,
                    data: [],
                    borderColor: 'rgba(75, 192, 192, 1)',
                    backgroundColor: 'rgba(75, 192, 192, 0.2)',
                    fill: true,
                },
            ],
        };
        return (<div>
            <h2>{title}</h2>
            <Line data={chartData}/>
        </div>);
    }

    const loadChartData = () => {
        const dataset = [];
        let minFreq = 0;
        let maxFreq = 0;
        Object.keys(velocitySeriesData).forEach(key => {
            const labels = velocitySeriesData[key].map(seriesDataPoint => seriesDataPoint[0]);
            const data = velocitySeriesData[key].map(seriesDataPoint => seriesDataPoint[1]);
            minFreq = Math.min(...labels);
            maxFreq = Math.max(...labels);
            dataset.push({
                label: key,
                data: data.map((value, index) => ({
                    x: labels[index],
                    y: value,
                })), // Random data points for demonstration
                borderColor: colors[key],
                borderWidth: 1,
                pointRadius: 0.7, // To remove points on the line
                tension: 0,
            },);

        });

        const range = maxFreq - minFreq;

        const determineStepSize = (range) => {
            if (range <= 10) return 2;
            if (range <= 60) return 10;
            if (range <= 125) return 25;
            if (range <= 300) return 50;
            if (range <= 1000) return 100;
            if (range <= 5000) return 500;
            return 1000;
        };

        const stepSize = determineStepSize(range);

        const fixedLabels = ['', '100', '200', '300', '400', '500', '600', '700', '800', '900', ''];

        const chartData = {
            labels: fixedLabels,
            datasets: dataset,
        };

        const options = {
            scales: {
                x: {
                    type: 'time',
                    unit: 'millisecond', // Use milliseconds as the unit for the x-axis
                    stepSize: 10,
                    time: {
                        displayFormats: {
                            millisecond: 'SSS', // Format to show milliseconds
                        },
                    },
                    min: minFreq,
                    max: maxFreq,
                    ticks: {
                        source: 'data',
                        maxRotation: 0,
                        autoSkip: true,
                        maxTicksLimit: 6,
                        callback: function (value, index, values) {
                            if (Math.floor(values[index].value % stepSize) === 0) {
                                return Math.floor(values[index].value);
                            }
                            return null;
                        },
                    },
                    title: {
                        display: true,
                        text: 'Frequency (Hz)',
                    },
                },
                y: {
                    title: {
                        display: true,
                        text: 'Velocity (mm/s)',
                    },
                    beginAtZero: true,
                },
            },
            plugins: {
                legend: {
                    display: true,
                    position: 'bottom',
                },
                tooltip: {
                    mode: 'index',
                    intersect: false,
                    callbacks: {
                        title: (tooltipItems) => {
                            return `${(Math.round(tooltipItems[0].raw.x * 100) / 100).toFixed(2)} Hz`
                        },
                        labels: (tooltipItems) => {
                            return {x: tooltipItems[0].raw.x, y: tooltipItems[0].raw.y, z: tooltipItems[0].raw.z};
                        }
                    },
                },
                zoom: {
                    zoom: {
                        mode: 'x',
                        drag: {
                            enabled: true,
                        },
                        onZoomComplete: ({chart}) => {
                            // Adjust step size after zoom
                            const xMin = chart.scales.x.min;
                            const xMax = chart.scales.x.max;
                            const zoomRange = xMax - xMin;
                            const newStepSize = determineStepSize(zoomRange);

                            // Update ticks to reflect the zoomed step size
                            chart.options.scales.x.ticks.callback = function (value, index, values) {
                                if (Math.floor(values[index].value % newStepSize) === 0) return Math.floor(values[index].value);
                                return null;
                            };
                            chart.update();
                        },
                    },
                },
            },
        };

        setChartData({options: options, data: chartData})
    }


    const handleResetZoom = () => {
        if (chartRef.current) {
            chartRef.current.resetZoom();
        }
    };

    if (chartData.data.length === 0) {
        return (<div>
            <h2>{title}</h2>
            <Card.Body></Card.Body>
        </div>);
    }

    console.log(chartData.data);
    return (
        <Container fluid>
            <Row className="align-items-center">
                <Col lg={onExpanded ? 9 : 10} sm={onExpanded ? 9 : 10}>
                    <h4 className="p-0 m-0">{title}</h4>
                </Col>
                <Col lg={onExpanded ? 3 : 2} sm={onExpanded ? 3 : 2}>
                    <div className="d-flex align-items-end justify-content-end me-1">
                        <Button variant="link" onClick={handleResetZoom}>
                            <FaRedo className="text-secondary"/>
                        </Button>
                        {onExpanded &&
                            <Button variant="link"
                                    onClick={(e) => onExpanded(title, undefined, undefined, velocitySeriesData, colors)}>
                                <FaExpand className="text-secondary"/>
                            </Button>}
                    </div>
                </Col>
            </Row>
            <Line ref={chartRef} data={chartData.data} options={chartData.options}/>
        </Container>
    );
};

export default FrequencySeriesVelocityChart;