import {ButtonGroup, Card, Col, Container, Row} from 'react-bootstrap';

import React, {useEffect, useState} from "react";
import axiosInstance from "../../interceptor/axiosInterceptor";
import {useAuth} from "../../guards/authProvider";
import TimeSeriesChartGrid from "../../components/Chart/TimeSeriesChartGrid";
import {useLocation, useNavigate} from "react-router-dom";
import Layout from '../../layout/default';
import Block from "../../components/Block/Block";
import StatusBadge from "../components/StatusBadge";
import {formatDateToLastSeen, formatInterval, getDeviceStatusBadgeVariant, getDeviceStatusTooltip} from "./DeviceList";
import DeviceStatusBadge from "./DeviceStatusBadge";
import AppToast from "../../components/toast/AppToast";
import Button from "react-bootstrap/Button";
import {FaChevronLeft} from "react-icons/fa";
import {ChartLegend} from "../../components";
import CalendarComponent from "../../components/Calendar/Calendar";
import VibrationDeviceTimeSelector from "./VibrationDeviceTimeSelector";
import ChartModal from "./ChartModal";

const deviceDataTimeSeriesConverter = (deviceData, device) => {

    if (deviceData && Object.entries(deviceData.sensorValues).length > 0) {
        //console.log('valid device data');
        const timeSeriesOfSensors = new Map();
        const timestamp = new Date(deviceData.timestamp + 'Z');

        //const timeString = deviceData.sensorDataMetadataMinDTO.timeId;
        //const [hours, minutes] = timeString.split(':').map(Number);

        const time = new Date(timestamp.getFullYear(), timestamp.getMonth(), timestamp.getDate(), timestamp.getHours(), timestamp.getMinutes(), 0);

        //console.log('time is', time);
        const interval = (1000 / deviceData.sensorDataMetadataMinDTO.odr);
        const timeValues = []
        let index = 0;
        for (const [_, data] of Object.entries(deviceData.sensorValues)) {
            const dataTimestamp = new Date((time.getTime() + (index * interval)) * 1000);
            timeValues.push(dataTimestamp.getTime());
            for (const [sensorName, value] of Object.entries(data)) {
                if (!timeSeriesOfSensors.has(sensorName)) {
                    timeSeriesOfSensors.set(sensorName, []);
                }
                timeSeriesOfSensors.get(sensorName).push([dataTimestamp, value]);
            }
            index++;
        }
        //console.log(timeValues);
        return timeSeriesOfSensors;
    } else {
        const timeSeriesOfSensors = new Map();
        for (const [idx, value] of Object.entries(device)) {
            for (const [sensorName, sensorValue] of Object.entries(value)) {
                timeSeriesOfSensors.set(sensorName, []);
            }
            break;
        }

        return timeSeriesOfSensors;
    }
}


const deviceDataFrequencyDataConverter = (deviceData, device) => {

    if (deviceData && Object.entries(deviceData.frequencyDomainData).length > 0) {
        const frequencySeriesOfSensors = new Map();
        let lastFreq;

        for (const [_, data] of Object.entries(deviceData.frequencyDomainData)) {
            let freq = data['freq'];
            lastFreq = freq;
            for (const [sensorName, value] of Object.entries(data)) {
                if (sensorName === 'freq') {
                    continue;
                }
                if (!frequencySeriesOfSensors.has(sensorName)) {
                    frequencySeriesOfSensors.set(sensorName, []);
                }
                frequencySeriesOfSensors.get(sensorName).push([freq, value]);
            }
        }
        const records = Object.entries(deviceData.frequencyDomainData).length;
        const odr = deviceData.sensorDataMetadataMinDTO.odr;
        const step = odr / records;
        /*for(let i = lastFreq + step; i < odr; i+=step)
        {
            frequencySeriesOfSensors.get('x').push([i,0.0]);
            frequencySeriesOfSensors.get('y').push([i,0.0]);
            frequencySeriesOfSensors.get('z').push([i,0.0]);

        }*/
        return frequencySeriesOfSensors;
    } else {
        const timeSeriesOfSensors = new Map();
        for (const [idx, value] of Object.entries(device)) {
            for (const [sensorName, sensorValue] of Object.entries(value)) {
                timeSeriesOfSensors.set(sensorName, []);
            }
            break;
        }

        return timeSeriesOfSensors;
    }
}

const calculateVelocityUsingAccelerationFrequency = (accelerationFrequencySeries) => {
    return new Map(
        Array.from(accelerationFrequencySeries).map(([key, arr]) => [
            key,
            arr.map(innerArray => {
                if (innerArray[1] !== 0 && innerArray[1] !== 0.0 && innerArray[0] !== 0) {
                    return [innerArray[0], innerArray[1] / (2 * Math.PI * innerArray[0])];
                } else {
                    return [innerArray[0], 0.0];
                }
            })
        ])
    );
}

const VibrationDeviceDetails = () => {

    const location = useLocation();
    const device = location.state?.device || [];
    const navigate = useNavigate();
    const colors = {'x': 'red', 'y': 'green', 'z': 'rgba(75, 192, 192, 1)'};
    const emptyTimeseries = new Map();
    emptyTimeseries.set('x', []);
    emptyTimeseries.set('y', []);
    emptyTimeseries.set('z', []);

    const getStartEndDateUTC = (now) => {
        // Ensure the input is a positive integer
        if (typeof now !== 'object') {
            throw new Error('Invalid date');
        }

        // Set the start date to the start of today in UTC
        const endDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);

        // Calculate the end date
        const startDate = new Date(endDate.getTime() - 24 * 60 * 60 * 1000 + 1000); // Add n days in milliseconds

        // Convert dates to ISO strings
        return {
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
        };
    };

    const {userDetails} = useAuth();

    const [fromToDates, setFromToDates] = useState(undefined);
    const [timeseriesForSensors, setTimeseriesForSensors] = useState(emptyTimeseries);
    const [frequencySeriesForSensors, setFrequencySeriesForSensors] = useState(emptyTimeseries);
    const [velocitySeriesForSensors, setVelocitySeriesForSensors] = useState(emptyTimeseries);

    const [deviceDataId, setDeviceDataId] = useState(undefined);
    const [selectedDate, setSelectedDate] = useState(undefined);
    const [deviceData, setDeviceData] = useState(undefined);
    const [expandedChart, setExpandedChart] = useState(null);

    const loadState = () => {
        const queryParams = new URLSearchParams(location.search);
        const {startDate, endDate} = getStartEndDateUTC(new Date());
        const from = queryParams.get('from') || startDate;
        const to = queryParams.get('to') || endDate;
        setSelectedDate(new Date(new Date(from).getTime() + 1000));
        setFromToDates({from: from, to: to});
        setTimeseriesForSensors(emptyTimeseries);
        setFrequencySeriesForSensors(emptyTimeseries);
        setVelocitySeriesForSensors(emptyTimeseries);
    }

    useEffect(() => {
        loadState();
    }, [location.search]);

    const fetchDeviceData = async (id) => {
        if (id === undefined || id === null || typeof id === "object") {
            return;
        }
        await axiosInstance.get(`companies/${userDetails.companyId}/branches/${device.branchId}/devices/${device.id}/data/${id}`)
            .then((response) => {
                if (response.status === 200) {
                    setDeviceData(response.data);
                    setTimeseriesForSensors(deviceDataTimeSeriesConverter(response.data, device));
                    const accelerationFrequencySeries = deviceDataFrequencyDataConverter(response.data, device);
                    setFrequencySeriesForSensors(accelerationFrequencySeries);
                    setVelocitySeriesForSensors(calculateVelocityUsingAccelerationFrequency(accelerationFrequencySeries));
                } else {
                    AppToast.error('Error while fetching device data metadata');
                }
            }).catch((error) => {
                AppToast.error(error.message);
                setTimeseriesForSensors(deviceDataTimeSeriesConverter(undefined, device));
                setFrequencySeriesForSensors(deviceDataFrequencyDataConverter(undefined, device));
            });
    }

    const handleDeviceDataIdChange = (id) => {
        fetchDeviceData(id).finally(() => {
            setDeviceDataId(id);
        });
    }

    const handleDateChange = (date) => {
        const {startDate, endDate} = getStartEndDateUTC(date);
        const queryParams = new URLSearchParams(location.search);
        queryParams.set('from', startDate);
        queryParams.set('to', endDate);
        navigate(`?${decodeURIComponent(queryParams.toString())}`, {state: {device: device}});
    };

    const onChartExpanded = (title, timeseriesData, frequencySeriesData, velocitySeriesData, colors) => {
        setExpandedChart({title, timeseriesData, frequencySeriesData, velocitySeriesData, colors});
    }
    const onBackPressed = () => {
        const queryParams = new URLSearchParams(location.search);
        navigate(`/devices?${decodeURIComponent(queryParams.toString())}`);
    }

    if (device.length === 0) {
        navigate(-1);
    }
    if (fromToDates === undefined) {
        return <Layout>
            <div>Loading..</div>
        </Layout>
    }
    return (
        <Layout title="Devices List" content="h-100 m-0 p-0">
            <Card>
                <Container fluid>
                    <Row className="justify-content-center align-items-center bg-light">
                        <div className="card-title-group flex flex-wrap align-items-center">
                            <Button variant="link" onClick={(e) => onBackPressed()}>
                                <FaChevronLeft className="btn-outline-primary"/>Back
                            </Button>
                            <div className="card-title">
                                <h5 className="title mb-0">{device.name} Details <DeviceStatusBadge classname={'m-1'}
                                                                                                    lastSeen={device.timestamp}
                                                                                                    conf={device.currentConfiguration}/>
                                </h5>
                            </div>
                            <ChartLegend.Group className="gap gx-3 align-items-center p-1">
                                <div className="gap-col">
                                    <ButtonGroup className="border">
                                        <CalendarComponent hasTimePicker={true} selectedDate={selectedDate}
                                                           onDateChange={handleDateChange}/>
                                        <VibrationDeviceTimeSelector
                                            selectedFilter={deviceDataId}
                                            onItemSelected={(deviceDataId) => handleDeviceDataIdChange(deviceDataId)}
                                            userDetails={userDetails} deviceId={device.id}
                                            branchId={device.branchId} from={fromToDates.from} to={fromToDates.to}/>
                                    </ButtonGroup>

                                </div>
                            </ChartLegend.Group>
                        </div>
                    </Row>
                    <Row className="justify-content-between">
                        <Col>
                            <Card.Body className="overflow-scroll">
                                <Block className="mt-1">
                                    <Row>
                                        <Col sm={6} lg={6}>
                                            <div className="bio-block">
                                                <ul className="list-group list-group-borderless small">
                                                    <li className="list-group-item">
                                                        <span
                                                            className="title fw-medium w-40 d-inline-block">Name</span>
                                                        <span className="text">{device.name}</span>
                                                    </li>
                                                    <li className="list-group-item">
                                                    <span
                                                        className="title fw-medium w-40 d-inline-block">Mac Addr</span>
                                                        <span className="text">{device.deviceEui}</span>
                                                    </li>
                                                    <li className="list-group-item">
                                                    <span
                                                        className="title fw-medium w-40 d-inline-block">Activation Status</span>
                                                        <StatusBadge status={device.deviceStatus}
                                                                     getVariant={getDeviceStatusBadgeVariant}
                                                                     tooltipText={getDeviceStatusTooltip}/>
                                                    </li>
                                                    <li className="list-group-item">
                                                        <span className="title fw-medium w-40 d-inline-block">ODR</span>
                                                        <span
                                                            className="text">{deviceData?.sensorDataMetadataMinDTO?.odr || '-'}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </Col>
                                        <Col sm={6} lg={6}>
                                            <div className="bio-block">

                                                <ul className="list-group list-group-borderless small">
                                                    <li className="list-group-item">
                                                    <span
                                                        className="title fw-medium w-40 d-inline-block">Last Seen</span>
                                                        <span
                                                            className="text">{formatDateToLastSeen(device?.timestamp)}</span>
                                                    </li>
                                                    <li className="list-group-item">
                                                    <span
                                                        className="title fw-medium w-40 d-inline-block">Read Interval</span>
                                                        <span
                                                            className="text">{formatInterval(device.readPeriod || device?.currentConfiguration?.readPeriod)}</span>
                                                    </li>

                                                    <li className="list-group-item">
                                                    <span
                                                        className="title fw-medium w-40 d-inline-block">Device Temperature</span>
                                                        <span
                                                            className="text">{(deviceData?.sensorDataMetadataMinDTO?.deviceTemperature | '-')} °C</span>
                                                    </li>
                                                    <li className="list-group-item">
                                                    <span
                                                        className="title fw-medium w-40 d-inline-block">FSR</span>
                                                        <span
                                                            className="text">{deviceData?.sensorDataMetadataMinDTO?.fsr || '-'}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </Col>
                                    </Row>
                                </Block>
                                <div className="mt-3 h-100">
                                    <TimeSeriesChartGrid sensorType="VIBRATION_SENSOR"
                                                         timeSeriesDataMap={timeseriesForSensors}
                                                         frequencySeriesDataMap={frequencySeriesForSensors}
                                                         velocitySeriesDataMap={velocitySeriesForSensors}
                                                         colors={colors} onChartExpanded={onChartExpanded}/>
                                    <ChartModal title={expandedChart?.title}
                                                timeSeriesData={expandedChart?.timeseriesData}
                                                frequencySeriesData={expandedChart?.frequencySeriesData}
                                                velocitySeriesData={expandedChart?.velocitySeriesData}
                                                colors={expandedChart?.colors}/>
                                </div>
                            </Card.Body>
                        </Col>
                    </Row>
                </Container>
            </Card>
        </Layout>
    );
}
export const DatePickerCustomInput = React.forwardRef(({value, onClick}, ref) => (
    <span onClick={onClick} ref={ref}>
        {value}
    </span>
));

export default VibrationDeviceDetails;