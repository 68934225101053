import React from 'react';
import {Button, Form, Modal, Spinner} from 'react-bootstrap';
import axiosInstance from "../../interceptor/axiosInterceptor";
import {useAuth} from "../../guards/authProvider";
import LocationSelector from "./LocationSelector";
import {Formik} from 'formik';
import * as Yup from 'yup';

const validationSchema = Yup.object({
    name: Yup.string()
        .required('Gateway name is required')
        .min(4, 'Gateway name should be at least 4 characters'),
    latitude: Yup.number()
        .required('Latitude is required'),
    longitude: Yup.number()
        .required('Longitude is required')
});


const EditGatewayModal = ({show, onHide, gateway}) => {
    const {userDetails} = useAuth();


    const handleSave = async (formData) => {
        try {
            const updatedDevice = await axiosInstance.put(`/companies/${userDetails.companyId}/branches/${gateway.branchId}/gateways/${gateway.id}`, formData);
            onHide(updatedDevice);
        } catch (error) {
            console.log(error);
        }
    };


    return (
        <Modal show={show} onHide={onHide} centered backdrop="static" animation>
            <Modal.Header closeButton>
                <Modal.Title>Edit Gateway - {gateway?.name}</Modal.Title>
            </Modal.Header>
            <Formik
                initialValues={{
                    name: gateway?.name || '',
                    latitude: gateway?.latitude || '',
                    longitude: gateway?.longitude || ''
                }}
                validationSchema={validationSchema}
                onSubmit={(values, {setSubmitting}) => {
                    handleSave(values).then((v) => {
                        setSubmitting(false);
                    }).catch((err) => {
                        console.log(err);
                        setSubmitting(false);
                    });
                }}
            >
                {({
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      values,
                      errors,
                      touched,
                      setFieldValue,
                      isSubmitting,
                  }) => (
                    <Form noValidate onSubmit={handleSubmit} onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            handleSubmit();
                        }
                    }}>
                        <Modal.Body>
                            <Form.Group controlId="formGatewayName">
                                <Form.Label>Gateway Name</Form.Label>
                                <Form.Control
                                    name="name"
                                    type="text"
                                    placeholder="Enter gateway name"
                                    autoFocus
                                    required
                                    value={values.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={touched.name && !!errors.name}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.name}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Location</Form.Label>
                                <LocationSelector
                                    initialLocation={{lat: values.latitude, lng: values.longitude}}
                                    onLocationSelect={setFieldValue}/>
                                <Form.Control.Feedback type="invalid">
                                    {errors.latitude}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={(e) => onHide(undefined)}>
                                Cancel
                            </Button>
                            <Button variant="primary" type="submit">
                                {isSubmitting ?
                                    (<Spinner animation="border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>) : 'Update'}
                            </Button>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

export default EditGatewayModal;
