import React, {useEffect, useRef, useState} from 'react';
import {MapContainer, Marker, Popup, TileLayer, Tooltip, useMap, ZoomControl} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';


delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

// Helper function to calculate center of all locations
const calculateCenter = (locations) => {
    if (locations.length === 0) {
        return [45.596805999999994, -73.340286];
    }
    const latitudes = locations.map(location => location.latitude);
    const longitudes = locations.map(location => location.longitude);

    const avgLat = latitudes.reduce((a, b) => a + b, 0) / latitudes.length;
    const avgLng = longitudes.reduce((a, b) => a + b, 0) / longitudes.length;

    return [avgLat, avgLng];
};

export function UpdateMapCenter({center}) {
    const map = useMap();

    // Update the map view when the center changes
    useEffect(() => {
        map.setView(center, map.getZoom());
    }, [center, map]);

    return null;
}

export const FitMapBounds = ({locations}) => {
    const map = useMap();

    useEffect(() => {
        if (locations && locations.length > 0) {
            const bounds = L.latLngBounds(locations.map(pos => [pos.latitude, pos.longitude]));
            map.fitBounds(bounds, {padding: [50, 50]}); // Add padding to the bounds if needed
        }
    }, [locations, map]);

    return null;
};

const MapWithLocations = ({locations}) => {
    const mapRef = useRef();
    // Calculate the initial center based on locations
    const [mapCenter, setMapCenter] = useState(calculateCenter(locations));

    // Adjust the center if locations change
    useEffect(() => {
        setMapCenter(calculateCenter(locations));
    }, [locations]);

    return (
        <MapContainer zoomControl={false} ref={mapRef} center={mapCenter} zoom={13} style={{height: '300px', width: '100%'}}>
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            />
            {locations.map((location, index) => (
                <Marker key={index} position={[location.latitude, location.longitude]}>
                    <Popup>
                        {location.name}
                    </Popup>
                    <Tooltip offset={[-8, -2]} opacity={1} permanent>
                        <span>{location.name}</span>
                    </Tooltip>
                </Marker>
            ))}
            <UpdateMapCenter center={mapCenter}/>
            <FitMapBounds locations={locations} />
            <ZoomControl position="bottomleft" />
        </MapContainer>
    );
};

export default MapWithLocations;
