import React, {useEffect, useState} from 'react';
import {ProgressBar} from 'react-bootstrap';
import './AppProgressBar.css';
import {useProgress} from "./ProgressProvider"; // Import CSS for styling

const AppProgressBar = () => {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const updateProgress = () => {
            setProgress((prev) => {
                // Exponential back-off calculation
                const increment = (100 - prev) * 0.05; // Adjust the factor to control the speed
                return prev + increment;
            });
        };

        // Set interval to update progress
        const interval = setInterval(updateProgress, 100); // Adjust the interval for smoother or faster updates

        // Clean up the interval on component unmount
        return () => clearInterval(interval);
    }, []);

    return (
            <ProgressBar variant="info" striped={false} className="progress-bar-overlay" animated now={progress}/>
    );
};

export default AppProgressBar;
