import {toast} from 'react-toastify';

const AppToast = {
    success: (content, options = {}) => {
        toast.success(content, {
            position: 'top-right',
            autoClose: 3000,
            ...options,
        });
    },
    error: (content, options = {}) => {
        toast.error(content, {
            position: 'top-right',
            autoClose: 3000,
            ...options,
        });
    },
    info: (content, options = {}) => {
        toast.info(content, {
            position: 'top-right',
            autoClose: 3000,
            ...options,
        });
    },
    warn: (content, options = {}) => {
        toast.warn(content, {
            position: 'top-right',
            autoClose: 3000,
            ...options,
        });
    },
    custom: (content, options = {}) => {
        toast(content, {
            position: 'top-right',
            autoClose: 3000,
            ...options,
        });
    },
};

export default AppToast;
