// axiosInstance.js
import axios from 'axios';
import AppToast from "../components/toast/AppToast";

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL, // Set your base API URL
});

const setupAxiosInterceptors = (progressProvider) => {
    const publicEndpointPrefixes = (process.env.REACT_APP_PUBLIC_ENDPOINT_PREFIXES || '')
        .split(',')
        .map(prefix => prefix
            .replace(/\*\*/g, '.*')   // Replace ** with .* to match multiple levels
            .replace(/\*/g, '[^/]*') // Replace * with [^/]* to match single segments
        );

    const publicEndpointsRegex = new RegExp(`^(${publicEndpointPrefixes.join('|')})`, 'i');

    axiosInstance.interceptors.request.use(
        config => {
            progressProvider.startLoading();
            if (!publicEndpointsRegex.test(config.url)) {
                //console.log("skipping auth for url " + config.url);
                return config;
            }

            const token = localStorage.getItem('token');
            if (token) {
                config.headers['Authorization'] = `Bearer ${token}`;
            }

            return config;
        },
        error => {
            progressProvider.stopLoading()
            AppToast.error(error.message);
        }
    );

    axiosInstance.interceptors.response.use(
        response => {
            progressProvider.stopLoading();
            return response;
        },
        error => {

            if (!error.response) {
                AppToast.error('Network error: Please check your connection or server is not reachable.');
            } else {
                // Handle other errors (e.g., 4xx or 5xx status codes)
                switch (error.response.status) {
                    case 400:
                        const message = error.response?.data?.message || error.message;
                        AppToast.error(`Error: ${message}`);
                        break;
                    case 401:
                        if (window.location.pathname.indexOf('/login') === -1) {
                            window.location = '/login';
                        }
                        localStorage.removeItem('token');
                        AppToast.error('Unauthorized. Please log in again.');
                        break;
                    case 403:
                        AppToast.error('Forbidden. You do not have access.');
                        break;
                    case 404:
                        AppToast.error('Not found. The requested resource could not be found.');
                        break;
                    case 500:
                        AppToast.error('Server error. Please try again later.');
                        break;
                    default:
                        AppToast.error('An error occurred. Please try again.');
                        break;
                }
            }
            progressProvider.stopLoading();
            return Promise.reject(error);
        }
    );

}
export const initializeAxios = (progressProvider) => {
    setupAxiosInterceptors(progressProvider);
};

export default axiosInstance;