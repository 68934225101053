import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useCompanyProvider} from "../../guards/companyProvider";
import {useAuth} from "../../guards/authProvider";
import {Dropdown} from "react-bootstrap";
import AppToast from "../../components/toast/AppToast";

const BranchSelector = () => {
    const {userDetails} = useAuth();
    const {company, getCompany} = useCompanyProvider();
    const navigate = useNavigate();
    const location = useLocation();
    const [branchDTOs, setBranchDTOs] = useState([]);
    let branchIdMap = {};
    if (company && company.branchDTOs) {
        company.branchDTOs.forEach((item) => {
            branchIdMap[item.id] = item;
        });
    }


    useEffect(() => {
        if (!company) {
            getCompany(userDetails.companyId).then((data) => {
                setBranchDTOs(data.branchDTOs);
                branchIdMap = {};
                branchDTOs.forEach((item) => {
                    branchIdMap[item.id] = item;
                })
            }).catch((err) => {
                AppToast.error(err.message);
            });
        } else {
            setBranchDTOs(company.branchDTOs);
        }
    }, [userDetails, company]);

    // Extract query parameter from the URL
    const queryParams = new URLSearchParams(location.search);
    const selectedFilter = decodeURIComponent(queryParams.get('branchId')) || 'all';
    if (!branchIdMap[selectedFilter]) {
        if (company && company.branchDTOs.length > 0) {
            queryParams.set('branchId', company.branchDTOs[0].id);
            navigate(`${location.pathname}?${decodeURIComponent(queryParams.toString())}`);
        }
    }

    // Handle dropdown selection
    const handleFilterChange = (event) => {
        //console.log(event);
        const filterValue = event;
        // Update the URL with the selected filter
        if (filterValue) {
            queryParams.set('branchId', filterValue);
        } else {
            queryParams.delete('branchId');
        }
        navigate(`${location.pathname}?${queryParams.toString()}`);
    };

    return (
        <div>
            <Dropdown value={selectedFilter} onSelect={handleFilterChange}>
                <Dropdown.Toggle variant="outline-primary"
                                 key="select-branch">{selectedFilter === 'all' ? 'Select branch' : branchIdMap[selectedFilter] === undefined ? "" : branchIdMap[selectedFilter].name}</Dropdown.Toggle>
                <Dropdown.Menu>
                    {branchDTOs?.map((branch) => {
                        return (<Dropdown.Item key={branch.id} eventKey={branch.id}>{branch.name}</Dropdown.Item>)
                    })}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
};

export default BranchSelector;
