import React from 'react';
import {OverlayTrigger, Tooltip} from "react-bootstrap";

const StatusBadge = ({status, getVariant, tooltipText}) => {
    const variant = getVariant(status);
    const tooltip = tooltipText(status);

    return (
        <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="button-tooltip-2" className="bg-white">{tooltip}</Tooltip>}>
            {({ref, ...triggerHandler}) => (
                <span ref={ref} {...triggerHandler} style={{cursor: 'pointer'}}
                      className={'badge border border-' + variant + ' text-' + variant}>{status}</span>)}
        </OverlayTrigger>)
};

export default StatusBadge;
