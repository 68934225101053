import React from 'react'
import {Navigate, Route, Routes} from "react-router-dom";

import {ScrollToTop} from '../components';

//Pages
import Blank from '../pages/Blank';

// apps
import UserList from '../pages/user-manage/UserList';

import Profile from '../pages/admin/Profile';


import AuthRegister from '../pages/auths/AuthRegister';
import AuthLogin from '../pages/auths/AuthLogin';
import AuthReset from '../pages/auths/AuthReset';

import NotFound from '../pages/error/NotFound';
import ProtectedRoute from "../guards/ProtectedRoute";
import {DevicePage} from "../pages/devices/DevicePage";
import DeviceDetails from "../pages/devices/DeviceDetailsPage";
import GatewayList from "../pages/gateways/GatewayList";
import VibrationDeviceDetails from "../pages/devices/VibrationDeviceDetailsPage";
import DashboardPage from "../pages/dashboard/DashboardPage";
import {DashboardProvider} from "../pages/dashboard/dashboardProvider";

function Router() {
    return (
        <ScrollToTop>
            <Routes>
                <Route path="blank" element={<Blank/>}/>
                <Route path="/" element={<Navigate to="/home"/>} />

                <Route path="/home" element={
                    <ProtectedRoute><DashboardProvider><DashboardPage/></DashboardProvider></ProtectedRoute>}/>
                <Route path="/gateways" element={<ProtectedRoute><GatewayList/></ProtectedRoute>}/>
                <Route path="/users" element={<ProtectedRoute><UserList/></ProtectedRoute>}/>
                <Route path="/profile" element={<ProtectedRoute><Profile/></ProtectedRoute>}/>
                <Route path="/devices">
                    <Route path="/devices" element={<ProtectedRoute><DevicePage/></ProtectedRoute>}/>
                    <Route path="/devices/:id/device-details"
                           element={<ProtectedRoute><DeviceDetails/></ProtectedRoute>}/>
                    <Route path="/devices/:id/vibration-device-details"
                           element={<ProtectedRoute><VibrationDeviceDetails/></ProtectedRoute>}/>
                </Route>

                <Route path="/register" element={<AuthRegister/>}/>
                <Route path="/login" element={<AuthLogin/>}/>
                <Route path="/forgotPassword" element={<AuthReset/>}/>

                <Route path="/not-found" element={<NotFound/>}/>
                <Route path="*" element={<NotFound/>}/>
            </Routes>
        </ScrollToTop>

    )
}

export default Router;
