import React from 'react';
import {Button, Form, Modal, Spinner} from 'react-bootstrap';
import axiosInstance from "../../interceptor/axiosInterceptor";
import {useAuth} from "../../guards/authProvider";
import LocationSelector from "./LocationSelector";
import {Formik} from 'formik';
import * as Yup from 'yup';

const validationSchema = Yup.object({
    name: Yup.string()
        .required('Gateway name is required')
        .min(4, 'Gateway name should be at least 4 characters'),
    gatewayEui: Yup.string()
        .matches(
            /^([0-9A-Fa-f]{2}:){7}[0-9A-Fa-f]{2}$/,
            'Name must be in the format 00:00:00:00:00:00:00:00 where each 00 is a hex value'
        )
        .length(23, 'Mac address should be 23 characters long')
        .required('Mac Address is required'),
    latitude: Yup.number()
        .required('Latitude is required'),
    longitude: Yup.number()
        .required('Longitude is required')
});

const AddGatewayModal = ({show, onAdd, selectedBranchId}) => {
    const {userDetails} = useAuth();


    const handleSave = async (formData) => {
        try {
            const updatedDevice = await axiosInstance.post(`/companies/${userDetails.companyId}/branches/${selectedBranchId}/gateways`, formData);
            onAdd(updatedDevice);
        } catch (error) {
            console.log(error);
        }
    };


    const formatName = (value) => {
        // Remove non-hex characters and colons
        const sanitizedValue = value.replace(/[^0-9A-Fa-f]/g, '').toUpperCase();
        // Add colons every two characters
        return sanitizedValue.match(/.{1,2}/g)?.join(':').slice(0, 23) || ''; // Limit to the correct format length
    };

    return (
        <Modal show={show} onHide={onAdd} centered backdrop="static" animation>
            <Modal.Header closeButton>
                <Modal.Title>Add Gateway</Modal.Title>
            </Modal.Header>
            <Formik
                initialValues={{
                    name: '',
                    gatewayEui: '',
                    latitude: undefined,
                    longitude: undefined
                }}
                validationSchema={validationSchema}
                onSubmit={(values, {setSubmitting}) => {
                    handleSave(values).then((v) => {
                        setSubmitting(false);
                    }).catch((err) => {
                        console.log(err);
                        setSubmitting(false);
                    });
                }}
            >
                {({
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      values,
                      errors,
                      touched,
                      setFieldValue,
                      isSubmitting,
                  }) => (
                    <Form noValidate onSubmit={handleSubmit} onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            handleSubmit();
                        }
                    }}>
                        <Modal.Body>
                            <Form.Group controlId="formGatewayName">
                                <Form.Label>Gateway Name</Form.Label>
                                <Form.Control
                                    name="name"
                                    type="text"
                                    placeholder="Enter gateway name"
                                    autoFocus
                                    required
                                    value={values.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={touched.name && !!errors.name}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.name}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="formMacAddress">
                                <Form.Label>Gateway Mac Address</Form.Label>
                                <Form.Control
                                    name="gatewayEui"
                                    type="text"
                                    placeholder="Enter mac address"
                                    value={values.gatewayEui}
                                    onChange={(e) => {
                                        setFieldValue('gatewayEui', formatName(e.target.value));
                                    }}
                                    onBlur={handleBlur}
                                    isInvalid={touched.gatewayEui && !!errors.gatewayEui}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.gatewayEui}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Location</Form.Label>
                                <LocationSelector
                                    initialLocation={{lat: values.latitude, lng: values.longitude}}
                                    onLocationSelect={setFieldValue}/>
                                <Form.Control.Feedback type="invalid">
                                    {errors.latitude}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={(e) => onAdd(undefined)}>
                                Cancel
                            </Button>
                            <Button variant="primary" type="submit">
                                {isSubmitting ?
                                    (<Spinner animation="border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>) : 'Add'}
                            </Button>
                        </Modal.Footer>
                    </Form>)}
            </Formik>
        </Modal>
    );
};

export default AddGatewayModal;
