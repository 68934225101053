import React from 'react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';

const FaultyMeasurementIndicator = ({isFaulty, message}) => {
    let tooltip = message ? message : 'Faulty Measurements indicates last measurement was not inside\n' +
        '                limits';
    if (!isFaulty) {
        tooltip = 'Last measurement was inside the limits and no abnormal values were detected';
    }
    return (
        <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="button-tooltip-2" className="bg-white">{tooltip}</Tooltip>}>
            {({ref, ...triggerHandler}) => (
                <span ref={ref} {...triggerHandler}
                      className={isFaulty ? 'badge border border-danger text-danger' : 'badge border border-success text-success'}>
            {isFaulty ? 'Faulty'.toUpperCase() : 'Normal'.toUpperCase()}
                    </span>)
            }
        </OverlayTrigger>
    )
};

export default FaultyMeasurementIndicator;
