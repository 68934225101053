import {Card} from 'react-bootstrap';

import Layout from '../../layout/default';
import Block from '../../components/Block/Block';
import {Icon, Image, Media} from '../../components';
import {useAuth} from "../../guards/authProvider";
import {useCompanyProvider} from "../../guards/companyProvider";
import DateFormat from "../../utilities/dateFormat";

function MyProfilePage() {
    const {user} = useAuth();
    const {company} = useCompanyProvider();
    const getUsersName = () => {
        if (user) {
            return user.firstName + ' ' + user.lastName;
        } else return '';
    }


    if (!user || !company) {
        return <div>Loading...</div>;
    }

    return (
        <Layout title="My Profile" content="container">
            <Block.Head>
                <Block.HeadBetween className="align-items-start">
                    <Block.HeadContent>
                        <div className="d-flex flex-column flex-md-row align-items-md-center">
                            <Media size="huge" shape="circle">
                                <Image src="/images/avatar/c.jpg" staticImage thumbnail alt="user"/>
                            </Media>
                            <div className="mt-3 mt-md-0 ms-md-3">
                                <h3 className="title mb-1">{getUsersName()}</h3>
                                <span className="small">{company.name}</span>
                            </div>
                        </div>
                    </Block.HeadContent>
                    {/*<Block.HeadBetween>
                        <div className="gap-col">
                            <ul className="d-flex gap g-2">
                                <li className="d-none d-md-block">
                                    <Link to="/edit-profile" className="btn btn-soft btn-primary">
                                        <Icon name="edit"></Icon>
                                        <span>Edit Profile</span>
                                    </Link>
                                </li>
                                <li className="d-md-none">
                                    <Link to="/edit-profile" className="btn btn-soft btn-primary btn-icon">
                                        <Icon name="edit"></Icon>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </Block.HeadBetween>*/}
                </Block.HeadBetween>
            </Block.Head>

            <Block className="mt-4">
                <Card className="card-gutter-md">
                    <Card.Body>
                        <div className="bio-block">
                            <h4 className="bio-block-title">Details</h4>
                            <ul className="list-group list-group-borderless small">
                                <li className="list-group-item">
                                    <span className="title fw-medium w-40 d-inline-block">Email:</span>
                                    <span className="text">{user.username}</span>
                                </li>
                                <li className="list-group-item">
                                    <span className="title fw-medium w-40 d-inline-block">Full Name:</span>
                                    <span className="text">{getUsersName()}</span>
                                </li>
                                <li className="list-group-item">
                                    <span className="title fw-medium w-40 d-inline-block">Role</span>
                                    <span className="text">{user.userAuthority}</span>
                                </li>
                                <li className="list-group-item">
                                    <span className="title fw-medium w-40 d-inline-block">Joining Date</span>
                                    <DateFormat isoDateString={user.createdAt} formatString={'dd MM yyyy'}></DateFormat>
                                </li>
                            </ul>
                        </div>
                    </Card.Body>
                </Card>
            </Block>

            <Block className="mt-4">
                <Card className="card-gutter-md">
                    <Card.Body>
                        <div className="bio-block">
                            <h4 className="bio-block-title">Branches</h4>
                            <ul className="list-group list-group-borderless small">
                                {company.branchDTOs?.map((branch, index) => (
                                    <li className="list-group-item"><Icon name="building"></Icon><span
                                        className="small p-1">{branch.name}</span></li>
                                ))}
                            </ul>
                        </div>
                    </Card.Body>
                </Card>
            </Block>

        </Layout>
    )
}

export default MyProfilePage;