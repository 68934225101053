import React, {useEffect, useRef, useState} from 'react';
import {Button, Card, Col, Form, Row} from 'react-bootstrap';
import {Link, useNavigate} from "react-router-dom";

import Layout from '../../layout/fullpage';
import PasswordStrengthBar from 'react-password-strength-bar';
import {API_BASE_URL} from "../../config/config";
import {Bounce, toast} from "react-toastify";
import axiosInstance from "../../interceptor/axiosInterceptor";


function AuthRegisterPage() {
    const [formData, setFormData] = useState({
        companyName: '',
        firstName: '',
        lastName: '',
        username: '',
        password: '',
        confirmPassword: '',
        address: ''
    });
    const [errors, setErrors] = useState({});
    const [validated, setValidated] = useState(false);
    const navigate = useNavigate();

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const firstInputRef = useRef(null);

    useEffect(() => {
        // Focus the first input when the component mounts
        if (firstInputRef.current) {
            firstInputRef.current.focus();
        }
    }, []);

    const validateForm = () => {
        const newErrors = {};
        const {companyName, firstName, lastName, username, password, confirmPassword, address} = formData;

        if (!username) newErrors.email = 'Email is required';
        else if (!/\S+@\S+\.\S+/.test(username)) newErrors.email = 'Email is invalid';

        if (!password) newErrors.password = 'Password is required';
        else if (password.length < 8) newErrors.password = 'Password must be at least 8 characters long';
        else if (!/(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=])/.test(password))
            newErrors.password = 'Password must contain at least one digit, one lowercase, one uppercase letter, and one special character';

        if (password !== confirmPassword) newErrors.confirmPassword = 'Passwords do not match';

        if (!firstName) newErrors.firstName = 'First Name is required';
        if (!lastName) newErrors.lastName = 'Last Name is required';
        if (!companyName) newErrors.companyName = 'Company Name is required';
        if (!address) newErrors.address = 'Address is required';

        return newErrors;
    };

    const handleSubmit = (e) => {
        //console.log('handle submit');
        e.preventDefault();
        const formErrors = validateForm();
        //console.log(formData);
        //console.log(formErrors);
        if (Object.keys(formErrors).length === 0) {
            setValidated(true);
            handleRegister()
        } else {
            setValidated(false);
            setErrors(formErrors);
        }
    };

    const handleRegister = () => {
        const registerFormData = {...formData};
        delete registerFormData.confirmPassword;
        axiosInstance.post(`${API_BASE_URL}/register`, registerFormData, {headers: {"Content-Type": "application/json"}})
            .then(data => {
                if (data.success) {
                    navigate('/login');
                } else {
                    toast.error('Register failed: ' + data?.error?.message, {
                        position: 'top-right',
                        autoClose: 3000,
                        transition: Bounce
                    });
                }
            })
            .catch(error => console.error('Error:', error));
    }

    return (
        <Layout title="Register" centered>
            <div className="container p-2 p-sm-4">
                <Card className="overflow-hidden card-gutter-lg rounded-4 card-auth card-auth-mh">
                    <Row className="justify-content-around">
                        <Col lg="8" sm="6">
                            <Card.Body>
                                <div className="nk-block-head text-center">
                                    <div className="nk-block-head-content">
                                        <h3 className="nk-block-title mb-1">Register your company</h3>
                                        <p className="small">Use your email email continue </p>
                                    </div>
                                </div>
                                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                    <Row className="gy-3">
                                        <Col className="col-12">
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="companyName">Company Name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    id="companyName"
                                                    name="companyName"
                                                    placeholder="Enter company name"
                                                    value={formData.companyName}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.companyName}
                                                    ref={firstInputRef}
                                                />
                                                <Form.Control.Feedback
                                                    className="error">{errors.companyName}</Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>

                                        <Col className="col-12">
                                            <Row>
                                                <Col className="col-6">
                                                    <Form.Group className="form-group">
                                                        <Form.Label>First Name</Form.Label>
                                                        <Form.Control
                                                            id="firstName"
                                                            type="text"
                                                            name="firstName"
                                                            placeholder="Enter first name"
                                                            value={formData.firstName}
                                                            onChange={handleChange}
                                                            isInvalid={!!errors.firstName}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.firstName}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>

                                                <Col className="col-6">
                                                    <Form.Group className="form-group">
                                                        <Form.Label>Last Name</Form.Label>
                                                        <Form.Control
                                                            id="lastName"
                                                            type="text"
                                                            name="lastName"
                                                            placeholder="Enter last name"
                                                            value={formData.lastName}
                                                            onChange={handleChange}
                                                            isInvalid={!!errors.lastName}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.lastName}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </Col>

                                        <Col className="col-12">
                                            <Form.Group className="form-group">
                                                <Form.Label>Email</Form.Label>
                                                <Form.Control
                                                    id="username"
                                                    type="email"
                                                    name="username"
                                                    placeholder="Enter email"
                                                    value={formData.username}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.username}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.username}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col className="col-12">
                                            <Form.Group className="form-group">
                                                <Form.Label>Password</Form.Label>
                                                <Form.Control
                                                    id="password"
                                                    type="password"
                                                    name="password"
                                                    placeholder="Enter password"
                                                    value={formData.password}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.password}
                                                />
                                                <PasswordStrengthBar minLength={8} password={formData.password}/>
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.password}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>

                                        <Col className="col-12">
                                            <Form.Group className="form-group">
                                                <Form.Label>Confirm Password</Form.Label>
                                                <Form.Control
                                                    id="confirmPassword"
                                                    type="password"
                                                    name="confirmPassword"
                                                    placeholder="Confirm password"
                                                    value={formData.confirmPassword}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.confirmPassword}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.confirmPassword}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>

                                        <Col className="col-12">
                                            <Form.Group className="form-group">
                                                <Form.Label>Address</Form.Label>
                                                <Form.Control
                                                    id="address"
                                                    type="text"
                                                    name="address"
                                                    placeholder="Enter address"
                                                    value={formData.address}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.address}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.address}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>

                                        <Col className="col-12">
                                            <Form.Check
                                                className="form-check-sm"
                                                type="checkbox"
                                                id="agreeCheckbox"
                                                label="I agree to privacy policy & terms"
                                            />
                                        </Col>
                                        <Col className="col-12">
                                            <div className="d-grid">
                                                <Button type="submit">Sign up</Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                                <div className="text-center mt-4">
                                    <p className="small">Already have an account? <Link
                                        to="/login">Login</Link></p>
                                </div>
                            </Card.Body>
                        </Col>

                    </Row>
                </Card>
            </div>
        </Layout>
    )
}

export default AuthRegisterPage;
