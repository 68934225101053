const apiCall = async (values, requests, onStart, onEnd, onError) => {
    //console.log(onStart);
    onStart();
    try {
        for (let i = 0; i < values.length; i++) {
            if (!values[i]) {
                await requests[i]();
            }
        }
    } catch (error) {
        //console.log(error);
        onError(error);
    } finally {
        //console.log("Completed fetching ...");
        onEnd();
    }
}

export default apiCall;