import {Card} from 'react-bootstrap';

import Layout from '../../layout/default';
import Block from '../../components/Block/Block';
import {useAuth} from "../../guards/authProvider";
import AppDataTable from "../components/AppDataTable";
import {useEffect, useState} from "react";
import {FaCheckCircle, FaCross, FaTimesCircle} from "react-icons/fa";


const UsersList = () => {
    const {userDetails} = useAuth();
    const [companyId, setCompanyId] = useState(userDetails.companyId);

    useEffect(() => {
        setCompanyId(userDetails.companyId);
    }, [userDetails.companyId]);
    const columns = [
        {
            name: 'Username',
            selector: (row) => row.username,
            sortable: true,
            searchable: true,
        },
        {
            name: 'Authority',
            selector: (row) => row.userAuthority,
            sortable: false,
        },
        {
            name: 'Name',
            selector: (row) => {
                return (row.firstName | '') + (row.lastName || '');
            },
            sortable: false,
        },
        {
            name: 'Enabled',
            selector: (row) => row.enabled,
            cell: row => row.enabled ? <FaCheckCircle color="green"/> : <FaCross color="red"/>,
            sortable: false,
        },
        {
            name: 'Locked',
            selector: (row) => row.accountNonLocked,
            cell: row => row.accountNonLocked ? <FaTimesCircle color="red"/> : <FaCheckCircle color="green"/>,
            sortable: false,
        }
    ];
    return (
        <Layout title="Users List" content="container">
            <Block.Head>
                <Block.HeadBetween>
                    <Block.HeadContent>
                        <Block.Title tag="h2">Users</Block.Title>
                    </Block.HeadContent>
                    <Block.HeadContent>
                    </Block.HeadContent>
                </Block.HeadBetween>
            </Block.Head>

            <Block>
                <Card> {
                    !companyId && <Card.Body>
                        <span>Select company to show users</span>
                    </Card.Body>
                }
                    {companyId &&
                        <AppDataTable title=""
                                      url={`companies/${companyId}/users`}
                                      columns={columns}/>
                    }
                </Card>
            </Block>
        </Layout>
    )
}

export default UsersList;